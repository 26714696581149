<template>
  <div v-if="!displayXS" id="map-view-sfeature" :class="collapsed ? 'collapsed' : ''">
    <slot/>
    <div id="sfeature-container" class="elevation-2">
      <div id="sfeature-info">
        <v-list-item>
          <v-list-item-content class="py-2">
            <slot name="name"/>
            <v-list-item-subtitle>
              <slot name="subtitle"/>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ma-0 d-flex flex-row align-center">
            <v-btn
              class="mr-1"
              icon
              small
              @click="changeCollapsed"
            >
              <v-icon>{{ collapsed ? 'mdi-unfold-more-horizontal' : 'mdi-unfold-less-horizontal' }}</v-icon>
            </v-btn>
            <v-btn
              icon
              small
              @click="$emit('close')"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <slot v-if="!collapsed" name="mode"></slot>
        <slot name="info"/>
        <slot v-if="!collapsed" name="tabs"/>
        <slot v-show="!collapsed" name="search"/>
      </div>
      <div v-show="!collapsed" id="sfeature-fields">
        <slot name="fields"/>
      </div>
      <v-card-actions v-show="!collapsed" id="sfeature-actions">
        <slot name="actions"/>
      </v-card-actions>
    </div>
  </div>
  <d-bottom-sheet v-else @close="close">
    <template v-slot:header>
      <v-list-item>
        <v-list-item-content class="pt-0">
          <slot name="name"/>
          <v-list-item-subtitle>
            <slot name="subtitle"/>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:content>
      <div class="pt-2">
        <slot name="info"/>
        <slot name="search"/>
        <div id="sfeature-fields">
          <slot name="fields"/>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-card-actions id="sfeature-actions">
        <slot name="actions"/>
      </v-card-actions>
    </template>
  </d-bottom-sheet>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import DBottomSheet from '@/components/utils/DBottomSheet.vue'
import { EventBus } from "@/event-bus";

export default {
  name: 'FeatureCard',
  components: { DBottomSheet },
  mixins: [displayServiceMixin],
  methods: {
    close () {
      this.$emit('close')
      EventBus.$emit('closeLpBottomSheet')
    },
    changeCollapsed () {
      this.collapsed = !this.collapsed
    }
  },
  computed: {
    collapsed: {
      get () {
        return this.$store.getters.getFeatureCardCollapsed
      },
      set (value) {
        this.$store.commit('setFeatureCardCollapsed', value)
      }
    }
  }
}
</script>

<style scoped>
#map-view-sfeature {
  position: absolute;
  z-index: 20;
  width: 336px;
  padding: 10px;
  top: 50px;
  height: calc(100% - 100px);
  right: 0;
}

#map-view-sfeature.collapsed {
  height: fit-content;
}

@media only screen and (max-width: 600px) {
  #map-view-sfeature {
    position: fixed;
    width: 100%;
    padding: 10px;
    top: inherit;
    bottom: 0;
    left: 0;
  }
}

#sfeature-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background: white;
}

#sfeature-fields {
  padding: 10px;
  flex-grow: 5;
  overflow-y: auto;
}

#sfeature-info {
  flex-grow: 0;
}

#sfeature-actions {
  flex-grow: 0;
}
</style>
