<template>
  <v-combobox
    v-model="model.value"
    :items="statusList"
    :placeholder="label"
    chips
    class="mb-2"
    clearable
    dense
    hide-details
    item-text="name"
    item-value="id"
    multiple
    outlined
    return-object
    small-chips
    @change="$emit('change')"
  >
    <template #selection="{item}">
      <v-chip :color="item.color" dark label small></v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { issueMixin } from '@/mixins/issue-mixin'

export default {
  name: 'StatusFilter',
  mixins: [issueMixin],
  props: {
    model: {
      type: Object,
      default: {
        value: [],
        itemText: ''
      }
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
