<template>
  <d-date-picker
    v-model="filterItem.fieldValue"
    class="mt-1"
    dense
    format="YYYY-MM-DD"
    hide-details
    outlined
  >
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn icon small style="margin-top: -1px" v-on="on">
            <v-icon small>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-list v-if="header.predicates.length > 0">
          <v-list-item
            v-for="predicate in header.predicates"
            :style="{backgroundColor: filterItem.predicate === predicate ? '#EEEEEE' : 'white'}"
            class="ma-0"
            dense
            @click="filterItem.predicate = predicate"
          >
            {{ $t(predicate) }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:prepend-inner>
      <v-icon v-if="!equalsMode" style="margin-top: 1px">
        {{ filterItem.predicate === 'LESS' ? 'mdi-less-than' : 'mdi-greater-than' }}
      </v-icon>
    </template>
  </d-date-picker>
</template>


<script>
import messages from '@/componet-locale/et-filter/messages'
import DDatePicker from '@/components/utils/DDatePicker.vue'
import { CONDITIONS } from '@/components/et/view-settings/utils'

export default {
  name: 'DateHeaderFilter',
  components: { DDatePicker },
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filterItem: Object,
    filter: Array
  },
  methods: {
    changeFieldValue (v) {
      this.filterItem.fieldValue = v
    },
    clear () {
      this.changeFieldValue('')
    }
  },
  computed: {
    equalsMode () {
      return this.filterItem.predicate === CONDITIONS.EQUAL
    }
  },
  watch: {
    'filterItem.fieldValue' (v) {
      this.filterItem.includeEmpty = !v
    }
  }
}
</script>
