<template>
  <div
    class="secondary-bar secondary-bar-text--text full-height"
  >
    <v-card-title :class="!fullscreen ? 'py-2 px-3' : 'pa-2'">
      <span v-if="!fullscreen">{{ $t('knowledgeBase') }}</span>
      <v-spacer v-if="!fullscreen"/>
      <v-btn v-if="fullscreen" class="mb-2" color="secondary-bar-text" icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn color="secondary-bar-text" icon @click="$emit('changeFullscreen')">
        <v-icon>{{ fullscreen ? 'mdi-menu-right' : 'mdi-menu-left' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!fullscreen" class="overflow-y-auto pa-0">
      <v-treeview
        v-if="tree"
        id="article-tree"
        ref="treeView"
        :active="selectedIds"
        :items="tree"
        :open="openedIds"
        activatable
        class="secondary-bar-text--text"
        color="secondary-bar-text"
        dense
        hoverable
        item-key="node.id"
        item-text="node.currentTitle"
        transition
        @update:active="selectArticle"
        @update:open="updateOpened"
      />
    </v-card-text>
  </div>
</template>

<script>
import messages from '@/componet-locale/wiki/messages'

export default {
  name: 'WikiArticlesBar',
  i18n: { messages },
  props: {
    tree: Array,
    selected: Object,
    loading: Boolean,
    fullscreen: Boolean
  },
  data: () => ({
    active: []
  }),
  methods: {
    selectArticle (articleIds) {
      if (articleIds.length) {
        this.$emit('select', articleIds[0])
      } else if (this.selected) {
        this.$nextTick(() => {
          this.$refs.treeView.nodes[this.selected.id].vnode.isActive = true
        })
      }
    },
    updateOpened (articleIds) {
      this.$store.dispatch('updateOpenedArticleIds', articleIds)
    }
  },
  computed: {
    selectedIds () {
      return this.selected ? [this.selected.id] : []
    },
    openedIds () {
      return this.$store.getters.getOpenedArticleIds
    }
  }
}
</script>


<style>

#article-tree .v-icon {
  color: var(--v-secondary-bar-text-base);
}

</style>
