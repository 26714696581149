<template>
  <span>{{ message.decryptedBody }}</span>
</template>

<script>
export default {
  name: 'SystemEventMessage',
  props: {
    message: Object
  }
}
</script>
