<template>
  <v-navigation-drawer
    v-if="dialog"
    v-model="dialog"
    :width="fullscreen ? '100%' : '80%'"
    absolute
    height="100vh"
    right
    style="z-index: 1000"
    temporary
    @input="onClose"
  >
    <div class="d-flex full-height overflow-hidden">
      <div :style="{width: fullscreen? '52px' : '280px'}">
        <wiki-articles-bar
          :fullscreen="fullscreen"
          :loading="treeLoading"
          :selected="selectedVersion && selectedVersion.article"
          :tree="articlesTree"
          @changeFullscreen="fullscreen = !fullscreen"
          @close="close"
          @select="selectLastVersion"
        />
      </div>
      <div class="flex-grow-1 d-flex flex-column">
        <wiki-toolbar
          v-if="setAbility('ADMIN') && selectedVersion"
          :edit-mode="mode === MODE.EDIT"
          :history-mode="mode === MODE.HISTORY"
          @changeHistoryMode="changeHistoryMode"
          @createNewArticle="createNewArticle"
          @edit="changeEditMode"
          @remove="openRemoveDialog"
          @save="saveVersion"
          @setParent="openSetParentArticleDialog"
          @share="share"
        />
        <div v-if="selectedVersion" style="height: 100%">
          <wiki-article-view
            v-if="mode === MODE.VIEW"
            :loading="articleLoading"
            :selected-version="selectedVersion"
          />
          <wiki-article-editor
            v-else-if="mode === MODE.EDIT"
            ref="editor"
            :selected-version="selectedVersion"
          />
          <wiki-article-history
            v-else-if="mode === MODE.HISTORY"
            :selected-version="selectedVersion"
          />
        </div>
        <div v-else class="pa-3">
          Выберите статью
        </div>
      </div>
    </div>

    <set-parent-article-dialog
      ref="setParentArticleDialog"
      @save="getArticlesTree"
    />
    <wiki-remove-dialog
      ref="removeDialog"
      :articles-tree="articlesTree"
      @remove="onRemove"
    />
    <new-article-dialog
      ref="newArticleDialog"
      @save="getArticlesTree"
    />
    <confirmation-dialog
      ref="closeConfirmationDialog"
      @confirm="() => close(true)"
    >
      <template #text>
        <span class="red--text">
          Закрыть редактор без сохранения?
        </span>
      </template>
    </confirmation-dialog>
    <share-data-dialog ref="shareDataDialog"/>
  </v-navigation-drawer>
</template>

<script>
import WikiArticlesBar from '@/components/wiki/WikiArticlesBar.vue'
import WikiArticleView from '@/components/wiki/WikiArticleView.vue'
import { EventBus } from '@/event-bus'
import WikiToolbar from '@/components/wiki/WikiToolbar.vue'
import SetParentArticleDialog from '@/components/wiki/SetParentArticleDialog.vue'
import WikiArticleEditor from '@/components/wiki/WikiArticleEditor.vue'
import _ from 'lodash'
import WikiArticleHistory from '@/components/wiki/WikiArticleHistory.vue'
import WikiRemoveDialog from '@/components/wiki/WikiRemoveDialog.vue'
import NewArticleDialog from '@/components/wiki/NewArticleDialog.vue'
import messages from '@/componet-locale/wiki/messages'
import '@toast-ui/editor/dist/toastui-editor.css'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import ShareDataDialog from '@/components/utils/ShareDataDialog.vue'

const MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  HISTORY: 'history'
}

export default {
  name: 'WikiContainer',
  i18n: { messages },
  components: {
    ShareDataDialog,
    ConfirmationDialog,
    NewArticleDialog,
    WikiRemoveDialog,
    WikiArticleHistory,
    WikiArticleEditor,
    SetParentArticleDialog,
    WikiToolbar,
    WikiArticleView,
    WikiArticlesBar
  },
  data: () => ({
    dialog: false,
    articlesTree: null,
    treeLoading: false,
    selectedVersion: null,
    articleLoading: null,
    mode: MODE.VIEW,
    fullscreen: false
  }),
  mounted () {
    EventBus.$on('openArticle', articleId => this.open(articleId))
  },
  methods: {
    open (articleId) {
      this.dialog = true
      this.init(articleId)
    },
    async onClose (v) {
      if (!v) {
        await this.$nextTick(() => this.dialog = true)
        this.close()
      }
    },
    close (forceClose = false) {
      if (!forceClose && this.mode === MODE.EDIT) {
        return this.$refs.closeConfirmationDialog.open()
      }
      this.dialog = false
      this.mode = MODE.VIEW
    },
    async init (articleId) {
      await this.getArticlesTree(articleId)
    },
    async getArticlesTree (articleId = null) {
      this.treeLoading = true
      return this.$axios
        .get('article/get-articles-tree')
        .then(response => {
          this.articlesTree = response.data
          const selectedArticleId = articleId || (this.selectedVersion && this.selectedVersion.article.id)
          if (selectedArticleId) this.selectLastVersion(selectedArticleId)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage')
          this.close()
        })
        .finally(() => this.treeLoading = false)
    },
    selectLastVersion (articleId) {
      this.articleLoading = true
      return this.$axios.get(`article/get-last-version-by-article/${articleId}`)
        .then(res => this.selectedVersion = res.data)
        .catch(() => EventBus.$emit('showErrorMessage'))
        .finally(() => this.articleLoading = false)
    },
    openSetParentArticleDialog () {
      if (this.selectedVersion) {
        this.$refs.setParentArticleDialog.open(this.selectedVersion.article)
      }
    },
    changeEditMode () {
      if (this.mode !== MODE.EDIT) {
        this.mode = MODE.EDIT
      } else {
        this.mode = MODE.VIEW
      }
    },
    saveVersion () {
      const newVersion = _.cloneDeep(this.selectedVersion)
      const newVersionProps = this.$refs.editor.getNewVersionProps()
      newVersion.id = 0
      newVersion.title = newVersionProps.title
      newVersion.indexNumber = newVersionProps.indexNumber
      newVersion.content = newVersionProps.content
      this.$axios.post(`article/add-new-version/${this.selectedVersion.article.id}`, newVersion)
        .then(res => {
          this.selectedVersion = res.data
          this.getArticlesTree()
          EventBus.$emit('showSuccessMessage', 'versionSaved')
          this.mode = MODE.VIEW
        })
    },
    changeHistoryMode () {
      if (this.mode !== MODE.HISTORY) {
        this.mode = MODE.HISTORY
      } else {
        this.mode = MODE.VIEW
      }
    },
    openRemoveDialog () {
      this.$refs.removeDialog.open(this.selectedVersion.article)
    },
    onRemove () {
      this.selectedVersion = null
      this.getArticlesTree()
    },
    createNewArticle () {
      this.$refs.newArticleDialog.open()
    },
    share () {
      this.$refs.shareDataDialog.share(window.location.origin + this.$router.resolve({
        path: '/', query: {
          articleId: this.selectedVersion.article.id
        }
      }).href)
    }
  },
  computed: {
    MODE: () => (MODE)
  }
}
</script>
