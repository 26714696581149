<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card class="d-flex flex-column" height="90vh">
      <div
        class="mt-2 mx-2 pa-2 flex-grow-1 overflow-y-auto"
        style="border-radius: 5px; border: 1px solid lightgray"
        v-html="html"
      />
      <v-card-actions class="pa-2">
        <v-btn
          color="black"
          outlined
          @click="close"
        >
          {{ $t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'WikiHtmlViewer',
  data: () => ({
    dialog: false,
    html: null
  }),
  methods: {
    open (html) {
      this.html = html
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.html = null
    }
  }
}
</script>
