<template>
  <div class="d-flex flex-column">
    <div v-show="!displayXS || isDataListView" class="pa-1"
         style="background: var(--v-grey-lighten5);border-bottom: 1px solid #cfcfd0;">

      <div class="float-right" style="padding-top: 2px">
        <v-btn color="primary" outlined @click="create">
          {{ $t('button.create') }}
        </v-btn>
      </div>

      <filters-panel v-if="!displayXS" :filter-list="filters" :sorting="sorting" @change="filterData"/>

      <v-card-actions v-if="displayXS">
        <v-btn class="px-1 mb-1 d-inline-block" icon large @click="$router.push(`/project/${selectedProject.id}/home`)">
          <v-icon>navigate_before</v-icon>
        </v-btn>
        <v-spacer/>
        <filters-panel
          :filter-list="filters"
          :sorting="sorting"
          class="d-inline-block"
          @change="filterData"
        />
      </v-card-actions>
    </div>


    <div class="d-flex flex-grow-1 overflow-hidden">
      <div v-show="!displayXS || isDataListView" :style="widthTable" style="height: 100%">
        <div v-show="panelExpanded" id="issue-list" :style="{borderRight: isDataListView ? '' : 'solid 1px #d6d2d2'}">
          <v-card-actions class="py-0">
            <div class="body-1 ml-2 grey--text text--darken-2">
              {{ $t('tasks') }}
              <v-chip label outlined small>{{ totalElements }}</v-chip>
            </div>
            <v-spacer/>
            <v-btn :disabled="isDataListView" icon @click="panelExpanded = !panelExpanded">
              <v-icon v-show="!isDataListView">
                {{ panelExpanded ? 'arrow_left' : 'arrow_right' }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <issue-table
            v-infinite-scroll="loadData"
            :full-width="isDataListView"
            :items="items"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="10"
            @open="open"
          />
        </div>

        <div
          v-show="!panelExpanded"
          class="pointer"
          style="border-right: solid 1px #d6d2d2; height: 100%"
          @click="panelExpanded = true"
        >
          <v-btn class="mb-2" icon>
            <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
          </v-btn>
          <div class="minimized-panel-label body-1 grey--text text--darken-2">
            {{ $t('tasks') }}
          </div>
        </div>
      </div>
      <div class="flex-grow-1" style="height: 100%">
        <router-view/>
      </div>
    </div>
    <new-issue-dialog ref="newIssueDialog" @on-save-issue="filterData"/>
  </div>
</template>

<script>
import NewIssueDialog from '@/components/issue/NewIssueDialog'
import IssueTable from '@/components/issue/IssueTable'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import FiltersPanel from '@/components/filters/FiltersPanel'
import { issueFilterMixin } from '@/mixins/issue-filter-mixin'
import messages from '@/componet-locale/issue-list/messages'

export default {
  name: 'IssueList',
  components: { IssueTable, NewIssueDialog, FiltersPanel },
  mixins: [displayServiceMixin, issueFilterMixin],
  data: () => ({
    panelExpanded: true,
    busy: false,
    cancel: undefined,
    items: [],
    selected: null,
    page: 1,
    totalElements: 0
  }),
  i18n: { messages },
  mounted () {
    EventBus.$on('issueStatusChanged', this.changeIssueStatus)
    const category = history.state.category
    const categoryFilter = this.filters.find(el => el.name === 'categoryList')
    if (category && categoryFilter) {
      categoryFilter.model.value = [category]
    }
  },
  methods: {
    create () {
      if (this.isDataListView) {
        this.$refs.newIssueDialog.open()
        return
      }
      this.$router.push(`/project/${this.selectedProject.id}/ISSUE`).then(() => {
        this.$refs.newIssueDialog.open()
      })
    },
    filterData () {
      this.page = 1
      this.items = []
      this.loadData()
    },
    loadData () {
      const CancelToken = this.$axios.CancelToken
      this.busy = true
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      let requestParam = {
        projectId: this.selectedProject.id
      }
      this.$axios
        .post('/issue/find-pageable-search-result', this.searchRequest,
          {
            params: requestParam,
            cancelToken: new CancelToken(function executor (c) {
              self.cancel = c
            })
          })
        .then(response => {
          if (response.data.content.length) {
            this.items.push(...response.data.content)
            this.page++
            this.busy = false
            this.totalElements = response.data.totalElements
          }
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    open (item) {
      this.selected = item
      this.panelExpanded = this.windowWidth >= 1060
      EventBus.$emit('openData')
      this.$router.push({
        name: 'view-issue',
        params: {
          projectId: this.selectedProject.id,
          issueId: item.id
        }
      })
    },
    changeIssueStatus (issue) {
      const oldIssue = this.items.find(el => el.id === issue.id)
      if (oldIssue) oldIssue.status = issue.status
    }
  },
  created () {
    if (this.windowWidth > 1100) {
      this.panelExpanded = true
      return
    }
    this.panelExpanded = this.$route.name === 'issue-list'
  },
  watch: {
    isDataListView (val) {
      if (val) {
        this.panelExpanded = true
        this.filterData()
      }
    }
  },
  computed: {
    isDataListView () {
      return this.$route.name === 'issue-list'
    },
    widthTable () {
      if (this.isDataListView) {
        return 'width: 100%'
      }
      if (this.panelExpanded) {
        return 'width: 280px;'
      } else {
        return 'width: 36px;'
      }
    },
    searchRequest () {
      let criteria = this.filters
        .map(filter => {
          if (filter.model.value instanceof Array) {
            let values = filter.model.value.map(v => v.id)
            return {
              name: filter.name,
              value: values
            }
          } else {
            return {
              name: filter.name,
              value: filter.model.value
            }
          }
        })

      return {
        page: this.page,
        rowsPerPage: 10,
        sortBy: this.sorting.model.value.sortBy,
        desc: this.sorting.model.value.desc,
        criteria: criteria
          .filter(c => {
            if (c.value instanceof Array) {
              return c.value.length
            } else {
              return c.value
            }
          })
      }
    }
  }
}
</script>
