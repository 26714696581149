<template>
  <v-card class="pa-3 mt-3" outlined>
    <v-card-actions class="pa-0 mb-3">
      <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
      <v-spacer/>
      <v-icon
        class="pointer"
        @click="$emit('remove', index)"
      >
        delete
      </v-icon>
    </v-card-actions>
    <v-card-text class="pa-3 overflow-auto">
      <v-autocomplete
        v-model="item.layer"
        :items="layers"
        :label="$t('layer')"
        :no-data-text="$t('noResults')"
        :search-input.sync="search"
        class="mb-3"
        clearable
        dense
        error-messages=""
        hide-details
        item-text="name"
        outlined
        return-object
      />
      <v-select
        v-if="item.layer && item.layer.type === 'LAYER_POI'"
        v-model="item.lpTemplate"
        :items="item.layer.poiTemplates"
        :label="$t('objectTemplate')"
        class="mb-3"
        dense
        hide-details
        item-text="name"
        outlined
        return-object
      />
      <v-select
        v-if="item.lpTemplate"
        v-model="item.lpSearchField"
        :items="item.lpTemplate.fields"
        :label="$t('layerParameter')"
        class="mb-3"
        dense
        hide-details
        item-text="name"
        outlined
        return-object
      />
      <v-select
        v-if="item.layer && item.layer.type === 'WMS'"
        v-model="item.wfsPropertyName"
        :items="wfsPropertiesNames"
        :label="$t('layerParameter')"
        class="mb-3"
        dense
        hide-details
        outlined
      />
      <!--      <v-autocomplete todo add with relation "layer property <-> et field" -->
      <!--        v-if="item.layer && item.layer.type === 'ZWS'"-->
      <!--        v-model="item.zwsFieldName"-->
      <!--        :items="zwsLayerFields"-->
      <!--        :label="$t('layerParameter')"-->
      <!--        :loading="loadingZwsFields"-->
      <!--        item-text="userName"-->
      <!--        item-value="name"-->
      <!--      />-->
      <v-select
        v-model="item.header"
        :items="et.headers"
        :label="$t('etParameter')"
        class="mb-3"
        dense
        hide-details
        item-text="fieldName"
        outlined
        return-object
      />
      <v-switch v-model="item.readonly" :label="$t('readonly')" dense hide-details/>
    </v-card-text>
  </v-card>
</template>

<script>
import messages from '@/componet-locale/et-to-layer-entry/messages'

export default {
  name: 'EtToLayerEntry',
  i18n: { messages },
  props: {
    et: Object,
    item: Object,
    index: Number
  },
  data: () => ({
    layers: [],
    search: '',
    wfsPropertiesNames: [],
    zwsLayerFields: [],
    loadingZwsFields: false
  }),
  mounted () {
    this.searchWmsFields()
    // this.searchZwsFields() todo add with relation "layer property <-> et field"
  },
  methods: {
    find () {
      this.$axios
        .get('/data/search-by-type', {
          params: {
            str: this.search || '',
            projectId: this.selectedProject.id,
            dataType: 'LAYER'
          }
        })
        .then(response => {
          this.layers = response.data
          if (this.item.layer) this.layers.push(this.item.layer)
        })
    },
    // async searchZwsFields () { todo add with relation "layer property <-> et field"
    //   if (!this.item.layer || this.item.layer.type !== 'ZWS') return
    //   this.loadingZwsFields = true
    //   zwsCommandBuilder.getLayerProps(this.item.layer)
    //     .then(() => this.zwsLayerFields = [...this.item.layer.props.values()].flat())
    //     .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    //     .finally(() => this.loadingZwsFields = false)
    // },
    searchWmsFields () {
      if (!this.item.layer || this.item.layer.type !== 'WMS') return
      this.$axios.get(`${window.location.origin}/geoserver/wfs`, {
        params: {
          service: 'wfs',
          version: '2.0.0',
          request: 'DescribeFeatureType',
          typeNames: this.item.layer.layerId,
          outputFormat: 'application/json'
        }
      })
        .then(res => {
          this.wfsPropertiesNames = res.data.featureTypes
            .map(type => type.properties.map(el => el.name))
            .flat()
        })
    }
  },
  watch: {
    search () {
      this.find()
    },
    'item.layer.type' (type) {
      if (type === 'WMS') {
        this.searchWmsFields()
      }
      // else if (type === 'ZWS') { todo todo add with relation "layer property <-> et field"
      //   this.searchZwsFields()
      // }
    }
  }
}
</script>
