<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>
        {{ $t('printMap') }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0 px-2">
        <div
          class="mb-3 map-for-export"
        >
          <MapForExport
            ref="map"
            :initialView="view"
            :map="data"
            :features="features"
          />
        </div>
        <div class="d-flex mb-3">
          <v-select
            v-model="scale"
            :items="scaleItems"
            :label="$t('scale')"
            dense
            hide-details
            outlined
            style="width: 50%"
          />
          <v-select
            v-model="resolution"
            :items="resolutionItems"
            class="ml-3"
            dense
            hide-details
            label="DPI"
            outlined
            style="width: 50%"
          />
        </div>
        <v-textarea
          v-model="notes"
          :label="$t('notes')"
          auto-grow
          class="mb-2"
          dense
          hide-details
          outlined
          row-height="12"
        />
        <div class="d-flex flex-wrap justify-space-between mt-2">
          <div>
            <v-btn-toggle
              v-model="selectedFormat"
              group
              mandatory
            >
              <v-btn value="a4">
                A4
              </v-btn>
              <v-btn value="a3">
                A3
              </v-btn>
              <v-btn value="a2">
                A2
              </v-btn>
              <v-btn value="a1">
                A1
              </v-btn>
            </v-btn-toggle>
          </div>
          <div>
            <v-btn-toggle
              v-model="orientation"
              group
              mandatory
            >
              <v-btn value="landscape">
                <v-icon left>crop_landscape</v-icon>
                {{ $t('landscape') }}
              </v-btn>
              <v-btn value="portrait">
                <v-icon left>crop_portrait</v-icon>
                {{ $t('portrait') }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <print-meta-data-menu ref="printMetaDataMenu"/>
        <v-spacer/>
        <v-btn :loading="printLoading" outlined @click="print">
          <v-icon left>print</v-icon>
          {{ $t('button.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as jspdf from 'jspdf'
import axios from 'axios'
import { font } from '@/components/map/helpers/TimesNewRoman'
import DMap from '@/components/map/DMap.vue'
import MapForExport from '@/components/map/MapForExport.vue'
import html2canvas from 'html2canvas'
import PrintMetaDataMenu from '@/components/map/helpers/PrintMetaDataMenu.vue'
import messages from '@/componet-locale/map-export-dialog/messages'

const DIMS = {
  a0: [1189, 841],
  a1: [841, 594],
  a2: [594, 420],
  a3: [420, 297],
  a4: [297, 210],
  a5: [210, 148]
}

const resolutionItems = [
  { text: '72 dpi', value: 72 },
  { text: '150 dpi', value: 150 },
  { text: '200 dpi', value: 200 },
  { text: '300 dpi', value: 300 }
]

const exportOptions = {
  useCORS: true,
  scale: 10,
}

export default {
  name: 'MapExportDialog',
  components: { PrintMetaDataMenu, MapForExport, DMap },
  i18n: { messages },
  props: {
    data: Object,
    view: Object,
    features: Array
  },
  data: () => ({
    orientation: 'landscape',
    dialog: false,
    selectedFormat: 'a4',
    notes: '',
    siteInfo: null,
    printLoading: false,
    scale: 0,
    resolution: 200,
    resolutionItems
  }),
  methods: {
    print () {
      this.printLoading = true
      const map = this.map.$map
      const format = this.selectedFormat
      const resolution = this.resolution
      const scale = this.scale
      const dim = this.orientation === 'landscape' ? DIMS[format] : DIMS[format].reverse()
      const width = Math.round((dim[0] * resolution) / 25.4)
      const height = Math.round((dim[1] * resolution) / 25.4)
      const viewResolution = map.getView().getResolution()
      const size = map.getSize()
      const config = this.$refs.printMetaDataMenu.getConfig()
      const scaleResolution = scale === 0
        ? null
        : scale * 48 / resolution

      let self = this
      map.once('rendercomplete', function () {

        html2canvas(map.getViewport(), exportOptions).then(function (canvas) {
          const pdf = new jspdf.jsPDF('landscape', undefined, format)
          pdf.addImage(
            canvas.toDataURL('image/jpeg'),
            'JPEG',
            10,
            10,
            dim[0] - 20,
            dim[1] - 20
          )

          pdf.addFileToVFS("Times New Roman.ttf", font)
          pdf.addFont("Times New Roman.ttf", "Times New Roman", "normal")
          pdf.setFont("Times New Roman")

          pdf.rect(
            10,
            10,
            (dim[0] - 20),
            (dim[1] - 20)
          )

          for (let key in config) {
            pdf.setFontSize(12)
            let coordinates
            let align
            let baseline
            switch (key) {
              case 'lt':
                coordinates = [11, 11]
                align = 'left'
                baseline = 'top'
                break
              case 'rt':
                coordinates = [dim[0] - 11, 11]
                align = 'right'
                baseline = 'top'
                break
              case 'lb':
                coordinates = [11, dim[1] - 11]
                align = 'left'
                baseline = 'bottom'
                break
              case 'rb':
                coordinates = [dim[0] - 11, dim[1] - 11]
                align = 'right'
                baseline = 'bottom'
                break
            }
            if (config[key] === 'TEXT') {
              pdf.text(self.notes, coordinates[0], coordinates[1], { align, baseline })
            } else if (config[key] === 'DATE') {
              pdf.text(self.formatDate(Date()), coordinates[0], coordinates[1], { align, baseline })
            } else if (config[key] === 'TITLE') {
              pdf.textWithLink(self.getMetaData(), coordinates[0], coordinates[1], {
                align, baseline, url: window.location.toString()
              })
            } else if (config[key] === 'SITE_INFO') {
              let icon = self.data.icon ? self.data.icon : self.siteInfo.COMPANY_LOGO
              pdf.addImage(
                icon,
                'PNG',
                key.startsWith('l') ? coordinates[0] : coordinates[0] - 15,
                key.endsWith('t') ? coordinates[1] : coordinates[1] - 15,
                15,
                15
              )
              pdf.setFontSize(16)
              pdf.text(
                self.siteInfo.COMPANY_NAME,
                key.startsWith('l') ? coordinates[0] + 17 : coordinates[0] - 17,
                key.endsWith('t') ? coordinates[1] + 2 : coordinates[1] - 14,
                { align, baseline: 'top' }
              )
              pdf.text(
                self.data.name,
                key.startsWith('l') ? coordinates[0] + 17 : coordinates[0] - 17,
                key.endsWith('t') ? coordinates[1] + 14 : coordinates[1] - 2,
                { align, baseline: 'bottom' }
              )
            }
          }

          pdf.autoPrint()
          pdf.save(`${self.getMetaData()} (${self.formatDate(Date())}).pdf`)

          self.printLoading = false

          map.setSize(size)
          map.getView().setResolution(viewResolution)
        })
      })

      const scaling = Math.min(width / size[0], height / size[1])
      map.setSize([width, height])
      map.getView().setResolution(scale === 0 || !scale
        ? viewResolution / scaling
        : scaleResolution
      )

    },
    open (view) {
      this.dialog = true
      this.loadSiteInfo()
      this.$nextTick(() => {
        this.$refs.map.setView(view)
      })
    },
    loadSiteInfo () {
      axios
        .get('settings/get-general')
        .then((response) => {
          this.siteInfo = response.data
        })
    },
    close () {
      this.dialog = false
    },
    getMetaData () {
      return this.siteInfo.COMPANY_NAME + ' / ' +
        this.data.project.name + ' / ' +
        this.data.name
    }
  },
  computed: {
    scaleItems () {
      return [
        { text: this.$t('default'), value: 0 },
        { text: '1:1 000', value: 1 },
        { text: '1:5 000', value: 5 },
        { text: '1:10 000', value: 10 },
        { text: '1:50 000', value: 50 },
        { text: '1:100 000', value: 100 },
        { text: '1:250 000', value: 250 },
        { text: '1:500 000', value: 500 }
      ]
    },
    map () {
      return this.$refs.map.$refs.map
    }
  }
}
</script>

<style scoped>
.map-for-export {
  width: 100%;
  max-width: 584px;
  height: 315px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid lightgray
}
</style>
