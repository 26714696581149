<template>
  <view-data-page
    :data="et"
    :dialog-mode="dialogMode"
    :selectedView="selectedView"
    :show-loader="downloading"
    :split-screen-mode="splitScreenMode"
    :tab-list="tabList"
    @selectView="view => selectedView = view"
  >
    <template v-slot:navigation>
      <div v-show="selectedView === 'filters'">
        <portal-target class="px-2 mb-5" name="et-filters-bar"/>
        <div class="px-2">
          <div class="d-flex justify-space-between align-center pb-2">
            <div class="bold">{{ $t('presets') }}</div>
            <v-btn icon small @click="showPresets = !showPresets">
              <v-icon>{{ showPresets ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
            </v-btn>
          </div>
          <v-expand-transition>
            <portal-target v-if="showPresets" name="et-preset-bar"/>
          </v-expand-transition>
        </div>
      </div>
      <div v-show="selectedView === 'settings'">
        <et-settings-bar/>
      </div>
    </template>
    <template slot="doc">
      <et-viewer
        v-if="!downloading"
        ref="etViewer"
        :et="et"
        :fullScreenMode="fullScreenMode"
        :split-screen-mode="splitScreenMode"
        @etUpdated="init"
      />
    </template>
    <template slot="actions">
      <v-list-item dense @click="EventBus.$emit('makeCopy')">
        <v-list-item-avatar>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('makeCopy') }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </view-data-page>
</template>

<script>

import ViewDataPage from '@/components/utils/ViewDataPage.vue'
import EtViewer from '@/components/et/EtViewer.vue'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et/messages'
import EtSettingsBar from '@/components/et/utils/EtSettingsBar.vue'
import EtPresetBar from '@/components/et/utils/EtPresetBar.vue'

export default {
  name: 'EtView',
  components: { EtPresetBar, EtSettingsBar, EtViewer, ViewDataPage },
  i18n: { messages },
  props: {
    dataId: Number,
    dialogMode: {
      type: Boolean,
      default: false
    },
    splitScreenMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      et: {
        name: '',
        owner: {
          firstName: ''
        },
        project: {
          name: ''
        }
      },
      selectedView: 'filters',
      tabList: [
        {
          icon: 'mdi-filter',
          view: 'filters',
          tooltipText: this.$t('filters')
        },
        {
          icon: 'message',
          view: 'comments',
          tooltipText: this.$t('comments')
        },
        {
          icon: 'mdi-cog',
          view: 'settings',
          tooltipText: this.$t('settings')
        }
      ],
      downloading: true,
      fullScreenMode: false,
      showPresets: true
    }
  },
  created () {
    this.init()
    EventBus.$on('frameResize', fullScreen => this.fullScreenMode = fullScreen)
  },
  methods: {
    init () {
      this.downloading = true
      this.$axios
        .get('data/get', { params: { id: this.dataId || this.id } })
        .then(response => {
          this.et = response.data
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          console.error(reason.data.message)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  },
  computed: {
    EventBus () {
      return EventBus
    },
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    id () {
      this.init()
    }
  }
}
</script>
