<template>
  <v-list-item dense @click="$emit('selectGroup')">
    <v-list-item-avatar class="mr-1">
      <v-icon class="handle pointer mr-1" small>drag_indicator</v-icon>
      <v-btn
        class="pointer"
        icon
        x-small
        @click.stop="group.visible = !group.visible"
      >
        <v-icon :color="group.visible ? 'grey' : 'red'">
          {{ group.visible ? 'visibility' : 'visibility_off' }}
        </v-icon>
      </v-btn>
    </v-list-item-avatar>
    <v-list-item-content>
      <span class="body-2">{{ group.name || `${group.features.length} объектов` }}</span>
    </v-list-item-content>
    <v-list-item-action class="ma-0">
      <v-menu>
        <template v-slot:activator="{on}">
          <v-btn icon x-small v-on="on">
            <v-icon>tune</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="isAddFeaturesAllowed"
            dense
            @click="$emit('addFeatures')"
          >
            <v-list-item-icon class="mr-0">
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('addObjects') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="$refs.settingsDialog.open(group)">
            <v-list-item-icon class="mr-2">
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('settings') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-menu v-if="relations.length > 0" nudge-right="4px" offset-x>
            <template v-slot:activator="{on}">
              <v-list-item dense v-on="on">
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-vector-intersection</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('findRelated') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list dense>
              <v-list-item v-for="relation in relations" @click="$emit('findRelatedFeatures', relation)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ relation.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item v-if="splitScreenMode" dense @click="$emit('applyEtFilter')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-database-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('openTable') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense @click="$emit('removeGroup')">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('button.remove') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
    <map-group-settings-dialog
      ref="settingsDialog"
      @updateGroup="updateGroup"
    />
  </v-list-item>
</template>

<script>
import MapGroupSettingsDialog from '@/components/map/groups/MapGroupSettingsDialog.vue'
import messages from '@/componet-locale/map-groups/messages'

export default {
  name: "MapGroupListItem",
  i18n: { messages },
  components: { MapGroupSettingsDialog },
  props: {
    map: Object,
    group: Object,
    isAddFeaturesAllowed: Boolean
  },
  methods: {
    updateGroup (group) {
      this.group.name = group.name
      this.group.strokeColor = group.strokeColor
      this.group.fillColor = group.fillColor
    }
  },
  computed: {
    relations () {
      return this.map.simulationRules.filter(relation =>
        this.group.features.some(f => f.layer.id === relation.sourceLayer.id)
      )
    },
    splitScreenMode () {
      return this.$route.matched[0].path.includes('split-screen')
    }
  }
}
</script>
