<template>
  <div>
    <v-text-field v-model="integrationName" class="ma-3" label="Name" outlined/>
    <v-textarea v-model="componentForm.template" auto-grow class="ma-3" label="Template" outlined/>
    <v-textarea v-model="componentForm.data" auto-grow class="ma-3" label="Data" outlined/>
    <v-textarea v-model="componentForm.methods" auto-grow class="ma-3" label="Methods" outlined/>
    <v-textarea v-model="componentForm.computed" auto-grow class="ma-3" label="Computed" outlined/>
    <v-textarea v-model="componentForm.mounted" auto-grow class="ma-3" label="Mounted" outlined/>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: "IntegrationEdit",
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    integrationName: '',
    componentForm: {
      template: '<div></div>',
      data: '() => ({})',
      methods: '{}',
      computed: '{}',
      mounted: '{}'
    }
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.integrationName = this.integration.name
      if (this.integration.componentTemplate) {
        const component = JSON.parse(this.integration.componentTemplate)
        this.componentForm.template = component.template
        this.componentForm.data = component.data
        this.componentForm.methods = JSON.stringify(component.methods, null, 4)
        this.componentForm.computed = JSON.stringify(component.computed, null, 4)
        this.componentForm.mounted = JSON.stringify(component.mounted, null, 4)
      }
    },
    async save () {
      const integration = {
        id: this.integration.id,
        name: this.integrationName,
        componentTemplate: JSON.stringify({
          template: this.componentForm.template,
          data: this.componentForm.data,
          methods: JSON.parse(this.componentForm.methods),
          computed: JSON.parse(this.componentForm.computed),
          mounted: JSON.parse(this.componentForm.mounted)
        })
      }
      return this.$axios.post('integration/save', integration)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('saved')
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>

