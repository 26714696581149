<template>
  <div>
    <v-select
      v-if="equalsMode"
      ref="stringHeaderFilter"
      v-model="filterItem.value"
      :items="selectStrings"
      :no-data-text="$t('noResults')"
      class="mt-1"
      dense
      hide-details
      menu-props="offsetY, bottom"
      multiple
      outlined
    >
      <template v-slot:append>
        <v-btn
          v-if="filterItem.fieldValue || filterItem.value[0]"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <v-btn v-else :loading="autocompleteLoading" icon small>
          <v-icon small>mdi-filter</v-icon>
        </v-btn>
      </template>
      <template v-slot:selection="{item, index}">
        <v-chip v-if="index === 0" label>
          {{ filterItem.value.length }}
        </v-chip>
      </template>
      <template v-slot:prepend-item>
        <v-text-field
          :value="filterItem.fieldValue"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
          @input="changeFieldValue"
        />
      </template>
    </v-select>
    <div v-else style="width: 100%; position: relative">
      <v-text-field
        ref="stringHeaderFilter"
        :value="filterItem.fieldValue"
        class="mt-1"
        dense
        hide-details
        multiple
        outlined
        @focus="onFocus"
        @focusout="focusout"
        @input="changeFieldValue"
      >
        <template v-slot:append>
          <v-btn
            v-if="filterItem.fieldValue
              || filterItem.value[0]
              || !filterItem.selectAll
              || !filterItem.includeEmpty
            "
            :loading="autocompleteLoading"
            elevation="0"
            icon
            small
            style="margin-top: -1px"
            @click="clear"
          >
            <v-icon color="primary">close</v-icon>
          </v-btn>
          <v-btn v-else :loading="autocompleteLoading" icon small style="margin-top: -1px">
            <v-icon small>mdi-filter</v-icon>
          </v-btn>
        </template>
        <template v-slot:prepend-inner>
          <v-chip
            v-if="filterItem.value.length
            || !filterItem.selectAll
            || !filterItem.includeEmpty"
            small
            style="margin-top: 1px"
          >
            {{ likeFilterInnerText }}
          </v-chip>
        </template>
      </v-text-field>
      <div v-if="stringLikeHeaderMenu" class="et-string-like-filter-menu">
        <v-card class="pa-0 ma-0 string-like-header-menu-list d-flex flex-column" max-height="400">
          <v-card-text class="overflow-y-auto pa-0 ma-0">
            <v-list>
              <v-list-item class="string-like-header-menu-list-item" @click="changeSelectAll()">
                <v-list-item-action class="mr-1">
                  <v-checkbox :input-value="selectAllValue"/>
                </v-list-item-action>
                <v-list-item-content>{{ $t('selectAll') }}</v-list-item-content>
              </v-list-item>
              <v-tooltip
                v-for="string in selectStrings"
                :key="string"
                bottom
                color="black"
                max-width="90vw"
                open-delay="1000"
              >
                <template v-slot:activator="{on}">
                  <v-list-item
                    class="string-like-header-menu-list-item"
                    @click="changeSelected(string)"
                    v-on="on"
                  >
                    <v-list-item-action class="mr-1">
                      <v-checkbox :input-value="isStringSelected(string)"/>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0" style="text-align: start; max-height: 40px">
                      {{ string }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                {{ string }}
              </v-tooltip>
            </v-list>
          </v-card-text>
          <v-card-actions class="elevation-1 ma-0 pa-0">
            <v-list-item class="string-like-header-menu-list-item" @click="changeIncludeEmpty()">
              <v-list-item-action class="mr-1">
                <v-checkbox :input-value="filterItem.includeEmpty"/>
              </v-list-item-action>
              <v-list-item-content>{{ $t('includeEmpty') }}</v-list-item-content>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import messages from '@/componet-locale/et-filter/messages'
import { CONDITIONS } from '@/components/et/view-settings/utils'
import _ from 'lodash'
// TODO fix menu btn (give opportunity to change filter-mode by filter btn), watch reference in numberFilter

export default {
  name: 'StringHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filterItem: Object,
    filter: Array
  },
  data: () => ({
    items: [],
    stringLikeHeaderMenu: false,
    filterMenu: false,
    autocompleteCancelTokenSource: null,
    autocompleteLoading: false,
    debounceAutocompleteUpdate: null
  }),
  mounted () {
    const debounceAutocomplete = _.debounce(this.updateAutocomplete, 500)
    this.debounceAutocompleteUpdate = () => {
      this.autocompleteLoading = true
      debounceAutocomplete()
    }
  },
  methods: {
    updateAutocomplete () {
      if (this.autocompleteCancelTokenSource) this.autocompleteCancelTokenSource.cancel()
      this.autocompleteCancelTokenSource = this.$axios.CancelToken.source()
      this.autocompleteLoading = true
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.filterItem.fieldValue || '',
          conditions: this.filter
        }, { cancelToken: this.autocompleteCancelTokenSource.token })
        .then(({ data }) => {
          this.items = data
        })
        .catch(er => er ? console.error(er.message) : null)
        .finally(() => this.autocompleteLoading = false)
    },
    clear () {
      const prevFieldValue = this.filterItem.fieldValue

      this.filterItem.fieldValue = ''
      this.filterItem.value = []
      this.filterItem.selectAll = true
      this.filterItem.includeEmpty = true

      if (prevFieldValue !== '') this.debounceAutocompleteUpdate()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    changeFieldValue (v) {
      this.filterItem.fieldValue = v
      if (!this.equalsMode) {
        this.filterItem.value = []
        this.filterItem.selectAll = true
      }
      this.debounceAutocompleteUpdate()
    },
    changeSelectAll () {
      this.filterItem.selectAll = !this.selectAllValue
      this.filterItem.includeEmpty = this.filterItem.selectAll
      this.filterItem.value = []
    },
    changeIncludeEmpty () {
      this.filterItem.includeEmpty
        = !this.filterItem.includeEmpty
    },
    changeSelected (string) {
      this.filterItem.value.includes(string)
        ? this.filterItem.value
          .splice(this.filterItem.value.indexOf(string), 1)
        : this.filterItem.value.push(string)
      if (this.filterItem.value.length === this.selectStrings.length
        && this.filterItem.selectAll
      ) {
        this.filterItem.value = []
        this.filterItem.selectAll = false
      }
    },
    isStringSelected (string) {
      if (this.filterItem.selectAll) {
        return !this.filterItem.value.includes(string)
      } else {
        return this.filterItem.value.includes(string)
      }
    },
    focusout (e) {
      if (!!e.relatedTarget &&
        (e.relatedTarget.className.includes('string-like-header-menu-list-item')
          || e.relatedTarget.className.includes('string-like-header-menu-list'))
      ) {
        this.$refs.stringHeaderFilter.focus()
      } else {
        this.stringLikeHeaderMenu = false
      }
      if (this.autocompleteCancelTokenSource) this.autocompleteCancelTokenSource.cancel()
    },
    onFocus (e) {
      this.stringLikeHeaderMenu = true
      if (e.sourceCapabilities) {
        this.debounceAutocompleteUpdate()
      }
    }
  },
  computed: {
    CONDITIONS () {
      return CONDITIONS
    },
    selectAllValue () {
      if (this.filterItem.value.length === 0) {
        return this.filterItem.selectAll
      } else return this.filterItem.value.length === this.selectStrings.length
    },
    selectStrings () {
      const res = this.equalsMode
        ? [
          ...this.filterItem.value,
          ...this.items.map(el => el.value)
        ]
        : this.items.map(el => el.value)
      return res
        .filter((function (item, pos) {
          return res.indexOf(item) === pos;
        }))
        .filter(el => el !== null && el !== '')
    },
    equalsMode () {
      return this.filterItem.predicate === this.CONDITIONS.EQUAL
    },
    likeFilterInnerText () {
      if (this.filterItem.selectAll) {
        return '-' + (this.filterItem.value.length
          + !this.filterItem.includeEmpty)
      } else {
        return this.filterItem.value.length
          + this.filterItem.includeEmpty
      }
    }
  }
}
</script>

<style>
.et-string-like-filter-menu {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  min-width: 120px
}
</style>
