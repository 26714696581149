<template>
  <d-date-picker
    :label="header.fieldName"
    :primaryBorder="hasChanged"
    :readonly="readonly"
    :value="value"
    class="mb-3 full-width"
    dense
    format="YYYY-MM-DD"
    hide-details
    outlined
    @input="val => $emit('update:value', val)"
  />
</template>

<script>
import DDatePicker from '@/components/utils/DDatePicker.vue'

export default {
  name: 'DateEtItem',
  components: { DDatePicker },
  props: {
    value: null,
    header: Object,
    readonly: Boolean,
    hasChanged: Boolean
  }
}
</script>
