<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card style="overflow: hidden">
      <v-card-title>Создать интеграцию</v-card-title>
      <v-card-title>
        <v-text-field v-model="integrationName" dense hide-details label="Название" outlined/>
      </v-card-title>
      <v-card-actions>
        <v-btn
          outlined
          @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          :disabled="integrationName.length === 0"
          color="primary"
          outlined
          @click="createIntegration"
        >
          {{ $t('button.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'CreateIntegrationDialog',
  data: () => ({
    dialog: false,
    integrationName: ''
  }),
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    createIntegration () {
      if (this.integrationName) {
        this.$axios.post('integration/save', { name: this.integrationName })
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
            this.$emit('integrationCreated')
            this.close()
          })
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
      }
    }
  }
}
</script>
