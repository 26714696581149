export const issueFilterMixin = {
  data: () => ({
    filters: [
      {
        name: 'name',
        type: 'text-field-filter',
        label: 'search',
        model: {
          value: ''
        },
        main: true
      },
      {
        name: 'categoryList',
        type: 'category-picker',
        label: 'categories',
        model: {
          color: 'deep-purple ',
          value: [],
          urlDataSource: '/category/get-all-for-project/',
          itemText: 'name'
        },
        main: true
      },
      {
        name: 'keywords',
        type: 'combo-box-filter',
        label: 'keyWords',
        model: {
          color: 'primary',
          value: [],
          urlDataSource: '/keyword/get-all-for-project/',
          itemText: 'value'
        },
        main: false
      },
      {
        name: 'performer',
        type: 'combo-box-filter',
        label: 'executor',
        model: {
          color: 'primary',
          value: [],
          urlDataSource: '/project/get-users-by-project/',
          itemText: function (item) {
            return item.firstName + ' ' + item.lastName
          }
        }
      },
      {
        name: 'status',
        type: 'status-filter',
        label: 'status',
        model: {
          value: [],
          itemText: 'key'
        }
      }
    ],
    sorting: {
      label: 'sorting',
      model: {
        value: {
          sortBy: 'id',
          desc: 'DESC'
        }
      }
    }
  })
}
