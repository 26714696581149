<template>
    <v-snackbar
        absolute
        v-model="snackbar"
        timeout="10000"
        color='#444444fa'
        style="left: 0; right: 0; bottom: 40px; z-index: 100"
    >
       <input
           type="text"
           class="pdf-page-snack-input px-3"
           :value="input"
           @input="changeInput"
           @focusout="changeCurrentPage"
           v-on:keyup.enter="changeCurrentPage"
       />
       <v-spacer/>
       {{"/"}}
       <v-spacer/>
       {{pageCount}}
    </v-snackbar>
</template>

<script>
export default {
  props: {
    currentPage: Number,
    pageCount: Number
  },
  data: () => ({
    snackbar: true,
    input: 1
  }),
  methods: {
    activateSnack () {
      this.snackbar = true
    },
    disableSnack () {
      this.snackbar = false
    },
    changeInput (e) {
      this.input = e.target.value.replace(/\D/, '')
    },
    changeCurrentPage (e) {
      if (e.target.value > 0 && Number(e.target.value) <= this.pageCount) {
        this.$emit('changeCurrentPage', Number(e.target.value))
      } else {
        this.input = this.currentPage
      }
    }
  },
  watch: {
    currentPage () {
      this.input = this.currentPage
    }
  }
}
</script>

<style scoped>
.pdf-page-snack-input:focus{
  outline: none;
}
.pdf-page-snack-input{
  text-align: center;
  width: 4rem;
  background-color: #7b8184;
  border-radius: 2px
}

::v-deep .v-snack__wrapper {
  min-width: 10rem;
}
</style>
