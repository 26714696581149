export const issueMixin = {
  data: () => ({
    statusList: null
  }),
  mounted () {
    if (!this.statusList) {
      this.$axios.get('issue/get-all-statuses')
        .then(res => this.statusList = res.data)
    }
  }
}
