<template>
  <v-app class="full-height full-width">
    <navigation-bar v-if="currentLoginUser"/>
    <v-dialog v-model="disclaimer"
              width="500">
      <v-card>
        <v-card-text>
          <p>
            {{ $t('testVersion') }}
          </p>
          <p>
            {{ $t('hintReference') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            outlined
            @click="disclaimer=false"
          >
            {{ $t('button.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-content style="height: 100%">
      <router-view/>
    </v-content>
    <snackbar/>
    <chat-container v-if="isAuthenticated"/>
    <abstract-data-view-dialog/>
  </v-app>
</template>

<script>
import ChatContainer from './components/chats/ChatContainer'
import ChatUtils from './components/chats/ChatUtils'
import Home from './components/Home'
import SearchBar from './components/utils/SearchBar'
import { EventBus } from './event-bus'
import Snackbar from './components/utils/Snackbar'
import NavigationBar from './components/NavigationBar'
import { getCurrentLocale, languages, setLocale } from './services/locale-switcher'
import AbstractDataViewDialog from '@/components/utils/AbstractDataViewDialog'

export default {
  name: 'App',
  components: {
    AbstractDataViewDialog,
    ChatContainer,
    ChatUtils,
    NavigationBar,
    Snackbar,
    SearchBar,
    Home
  },
  methods: {
    onLogin () {
      this.disclaimer = true
    },
    setLocale (locale) {
      setLocale(locale)
    },
    getCurrentLocale () {
      const currentLocale = this.languages.find((item) => item.value === getCurrentLocale())
      return currentLocale ? currentLocale.title : 'English'
    }
  },
  computed: {
    user () {
      return this.currentLoginUser ? this.currentLoginUser.firstName[0] + this.currentLoginUser.lastName[0] : ''
    },
    languages () {
      return languages
    }
  },
  created () {
    if (localStorage.hasOwnProperty('currentLoginUser'))
      this.$ws.connect()
  },
  mounted () {
    EventBus.$on('login', () => this.onLogin())
    EventBus.$on('logout', () => this.logout())

    this.$axios.interceptors.response.use(response => {
      return Promise.resolve(response)
    }, error => {
      if (!error || !error.response || !error.response.status) {
        // TODO change due to cancel token
        // EventBus.$emit('showErrorMessage', 'Нет связи с сервером.')
      } else if (
        error.response.config.url.endsWith('login') ||
        error.response.config.url.endsWith('logout') ||
        error.response.config.url.endsWith('set-password') ||
        error.response.config.url.includes('zws')
      ) {
        // no-op
      } else if (!localStorage.hasOwnProperty('currentLoginUser')) {
        this.logout()
      } else if (error.response.status === 401) {
        this.logout()
      } else if (error.response.status === 403) {
        EventBus.$emit('showErrorMessage', this.$t('noPermission'))
      }
      return Promise.reject(error.response)
    })
  },
  beforeDestroy () {
    this.$ws.disconnect()
  },
  data: () => ({
    disclaimer: false
  })
}
</script>
