import axios from 'axios'

function saveLayerPoi (poi, onSuccess = () => {
}, onError = () => {
}) {
  let _poi = { ...poi }
  _poi.geometry = JSON.stringify(_poi.geometry)
  _poi.layer = _poi.layer ? { id: poi.layer.id } : _poi.layerId
  axios
    .post('layer-poi/save', _poi)
    .then(response => onSuccess(response))
    .catch(error => onError(error))
}

function getNameOfLayerPoi ({ properties, template }) {
  if (!properties) return ''

  let nameProps = properties
    .filter(it => it.field.partOfName && it.string)
    .map(it => it.string)

  if (nameProps.length) {
    let name = nameProps.join(' | ')
    if (name.length > 150) {
      return name.slice(0, 150) + '...'
    } else {
      return name
    }
  }
  if (properties.length && properties[0].string) {
    return properties[0].string
  } else {
    return template.name
  }
}

function getRouteToLayerPoi (projectId, poi, mapId = null) {
  const route = {
    query: {
      layerPoiId: poi.id
    },
    params: { projectId }
  }
  if (mapId) {
    route.name = 'map-view'
    route.params.id = mapId
  } else if (poi.layer) {
    route.name = 'layer-poi-table'
    route.params.id = poi.layer.id
  } else {
    route.fullPath = `${window.location.pathname}?layerPoiId=${poi.id}`
  }
  return route
}

function getMapListByLayerPoi (poi, onSuccess, onError) {
  axios
    .get('map/find-by-layer', {
      params: { layerId: poi.layer.id }
    })
    .then(response => {
      if (response.data.length) {
        onSuccess(response)
      } else {
        onError(response)
      }
    })
    .catch(error => onError(error))
}

export { saveLayerPoi, getNameOfLayerPoi, getRouteToLayerPoi, getMapListByLayerPoi }
