<template>
  <v-textarea
    :class="hasChanged ? 'changed-et-item' : ''"
    :clearable="!readonly"
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    auto-grow
    class="mb-3"
    dense
    hide-details
    outlined
    row-height="1"
    @input="val => $emit('update:value', val)"
  >
    <template v-if="header.commentField" v-slot:append>
      <v-icon>mdi-message-reply-text</v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'StringEtItem',
  props: {
    value: null,
    header: Object,
    readonly: Boolean,
    hasChanged: Boolean
  }
}
</script>

<style>
.changed-et-item .v-input__control .v-input__slot fieldset {
  border: 2px dashed black;
}
</style>
