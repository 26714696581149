<template>
  <div class="full-width">
    <v-select
      :append-icon="value ? 'mdi-close' : 'mdi-menu-down'"
      :class="hasChanged ? 'changed-et-item' : ''"
      :item-text="key"
      :item-value="header.helperPrimaryKey"
      :items="filteredSelectItems"
      :label="header.fieldName"
      :readonly="readonly"
      :value="value"
      class="mb-3 helper-values-select full-width"
      dense
      hide-details
      outlined
      @change="val => $emit('update:value', val)"
      @click:append="$emit('update:value', null)"
    >
      <template v-slot:prepend-item>
        <v-text-field
          v-model="inputValue"
          :readonly="readonly"
          class="mx-3 pt-3 mb-2"
          clearable
          dense
          hide-details
          solo
          @click:clear="inputValue = ''"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-item/messages'

export default {
  name: 'HelperValuesEtItem',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    value: null,
    shortMode: Boolean,
    emptyInitialValue: Boolean,
    hasChanged: Boolean,
    readonly: Boolean
  },
  data: () => ({
    selectItems: [],
    inputValue: '',
    initialValue: null,
  }),
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      if (!this.emptyInitialValue) await this.getInitialValue()
      this.updateValues()
    },
    updateValues () {
      this.$axios
        .post('et/query/search-helper-values', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue,
          primaryValue: this.value || null
        })
        .then(({ data }) => {
          this.selectItems = [...new Set(data)]
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', this.$t(e.data.description || e.data.message || 'searchHelperValueError'))
        })
    },
    async getInitialValue () {
      await this.$axios
        .post('et/query/search-helper-values', {
          tableId: this.tableId,
          header: this.header,
          value: this.value
        }, {
          params: {
            equalMode: true
          }
        })
        .then(({ data }) => {
          if (data[0]) this.initialValue = data[0]
          this.$emit('update:value', data[0][this.header.helperPrimaryKey])
        })
        .catch(er => console.error(er.message))
    },
  },
  computed: {
    filteredSelectItems () {
      const res = [...this.selectItems]
      if (this.initialValue) res.unshift(this.initialValue)
      return [...new Set(res)]
    },
    key () {
      return this.header.key.replace(/\s*.*\./gi, "")
    }
  },
  watch: {
    inputValue () {
      this.updateValues()
    }
  }
}
</script>


<style>
.helper-values-select input::placeholder {
  color: black !important;
  max-width: 100%;
}
</style>
