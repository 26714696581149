<template>
  <div class="pa-2 d-flex flex-column" style="height: 100%">
    <v-text-field
      v-model="title"
      :label="$t('header')"
      class="mb-2"
      dense
      hide-details
      outlined
    />
    <v-text-field
      v-model="indexNumber"
      :label="$t('index')"
      class="mb-2"
      dense
      hide-details
      outlined
      type="number"
    />
    <div style="height: 100%">
      <editor
        ref="editor"
        height="100%"
        initialEditType="wysiwyg"
      />
    </div>
  </div>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor'
import messages from '@/componet-locale/wiki/messages'

export default {
  name: 'WikiArticleEditor',
  i18n: { messages },
  components: { Editor },
  props: {
    selectedVersion: Object
  },
  data: () => ({
    title: '',
    indexNumber: 0
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.title = this.selectedVersion.title
      this.indexNumber = this.selectedVersion.indexNumber
      this.setHtml(this.selectedVersion.content)
    },
    setHtml (content) {
      this.$refs.editor.invoke('setHtml', content)
    },
    getNewVersionProps () {
      return {
        title: this.title,
        indexNumber: this.indexNumber,
        content: this.$refs.editor.invoke('getHtml')
      }
    }
  },
  watch: {
    selectedVersion () {
      this.init()
    }
  }
}
</script>
