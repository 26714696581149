<template>
  <v-dialog v-if="dialog" v-model="dialog" :width="500">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="presetName"
          :label="$t('presetName')"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined @click="close">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="presetName.length === 0" color="primary" outlined @click="savePreset">
          {{ preset ? $t('update') : $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et/messages'

export default {
  name: 'SavePresetDialog',
  i18n: { messages },
  props: {
    et: Object,
    filter: Array,
    sortingConfig: Array,
    mainPredicate: String
  },
  data: () => ({
    dialog: false,
    preset: null,
    presetName: ''
  }),
  methods: {
    open (preset) {
      this.preset = preset
      if (preset) {
        this.presetName = preset.name
      }
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.preset = null
      this.presetName = ''
    },
    savePreset () {
      if (this.preset) {
        this.updatePreset()
      } else {
        this.saveNewPreset()
      }
    },
    saveNewPreset () {
      this.$axios.post('et/preset', {
        tableId: this.et.id,
        pageable: { page: 0, rowPerPage: 0 },
        mainPredicate: this.mainPredicate,
        sortingConfig: this.sortingConfig,
        conditions: this.filter
      }, {
        params: {
          name: this.presetName
        }
      })
        .then(res => {
          this.$emit('updatePresets', res.data)
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    updatePreset () {
      this.$axios.post('et/update-preset', {
        tableId: this.et.id,
        pageable: { page: 0, rowPerPage: 0 },
        mainPredicate: this.mainPredicate,
        sortingConfig: this.sortingConfig,
        conditions: this.filter
      }, {
        params: {
          presetId: this.preset.id,
          presetName: this.presetName
        }
      })
        .then(() => {
          this.$emit('updatePresets', this.preset)
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
  }
}
</script>
