<template>
  <div>
    <div class="d-flex justify-space-between align-center pb-2">
      <div class="bold">{{ $t('appliedFilters') }}</div>
      <v-btn icon small @click="showFilters = !showFilters">
        <v-icon>{{ showFilters ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
      </v-btn>
    </div>
    <div v-if="selectedPreset" class="et-filter-card mt-1 mb-4 pa-2 elevation-4">
      <div class="full-width d-flex align-center">
        <div>
          <div class="font-italic caption">Выбран шаблон</div>
          <div class="font-weight-bold text-truncate">{{ selectedPreset.name }}</div>
        </div>
        <v-spacer/>
        <v-btn icon small style="margin-top: -1px" @click="clearPreset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="showFilters">
        <div
          v-for="filter in filters"
          v-if="!hideFilter(filter)"
          class="et-filter-card pa-2 mb-2"
        >
          <div class="mb-1 full-width d-flex">
            <div class="text-truncate">{{ filter.header.fieldName }}</div>
            <v-spacer/>
            <v-btn icon small style="margin-top: -1px" @click="$emit('clearFilter', filter)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <div
              v-if="filter.fieldValue && filter.selectAll"
            >
              <v-tooltip :open-delay="1500" bottom color="black">
                <template v-slot:activator="{on}">
                  <v-chip
                    class="mb-1 pointer"
                    close
                    color="primary"
                    outlined
                    @click:close="$emit('clearFilterFieldValue', filter)"
                  >
                <span class="text-truncate" style="max-width: 90%">
                  {{ getFieldValueFilterString(filter) }}
                </span>
                  </v-chip>
                </template>
                {{ getFieldValueFilterString(filter) }}
              </v-tooltip>
            </div>
            <div v-for="value in filter.value">
              <v-tooltip :open-delay="1500" bottom color="black">
                <template v-slot:activator="{on}">
                  <v-chip
                    :color="getValueFilterColor(filter)"
                    class="mb-1 pointer"
                    close
                    light
                    outlined
                    v-on="on"
                    @click:close="dropFilterValue(filter, value)"
                  >
            <span class="text-truncate" style="max-width: 90%">
              {{ getValueFilterString(filter, value) }}
            </span>
                  </v-chip>
                </template>
                {{ getValueFilterString(filter, value) }}
              </v-tooltip>
            </div>
            <div>
              <v-chip
                v-if="filter.selectAll !== filter.includeEmpty"
                class="mb-1"
                close
                color="primary"
                outlined
                @click:close="changeIncludeEmpty(filter)"
              >
                {{ getEmptyFilterString(filter) }}
              </v-chip>
            </div>
            <div>
              <v-chip
                v-if="showExcludeAllText(filter)"
                class="mb-1"
                close
                color="error"
                outlined
                @click:close="$emit('clearFilter', filter)"
              >
                {{ $t('excludeAll') }}
              </v-chip>
            </div>
          </div>
        </div>
        <slot/>
        <div v-if="showResetFilterButton" class="mt-2 d-flex flex-column">
          <v-btn
            class="full-width mb-2"
            outlined
            @click="clearPreset"
          >
            <v-icon class="mr-2" small>mdi-filter-remove</v-icon>
            {{ $t('resetFilter') }}
          </v-btn>
          <v-btn
            class="full-width"
            color="primary"
            outlined
            @click="savePreset"
          >
            <v-icon class="mr-2" small>mdi-filter-plus</v-icon>
            {{ this.selectedPreset ? $t('updatePreset') : $t('savePreset') }}
          </v-btn>
        </div>
        <div v-else class="ml-1 my-2">
          {{ $t('noAppliedFilters') }}
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import messages from '@/componet-locale/et/messages'
import sharedMessages from '@/componet-locale/et-filter/messages'

export default {
  name: 'EtFilterBar',
  i18n: { messages, sharedMessages },
  props: {
    filters: {
      type: Array,
      default: []
    },
    showResetFilterButton: Boolean
  },
  data: () => ({
    showFilters: true,
    selectedPreset: null
  }),
  methods: {
    dropFilterValue (filter, value) {
      if (['STRING', 'NUMBER'].includes(filter.header.fieldType)
        && filter.value && filter.value.length === 1
        && !filter.selectAll && !filter.includeEmpty
        && !filter.fieldValue
      ) {
        this.$emit('clearFilter', filter)
      } else {
        this.$emit('dropFilterValue', { filter, value })
      }
    },
    changeIncludeEmpty (filter) {
      if (['STRING', 'NUMBER'].includes(filter.header.fieldType)
        && (!filter.value || filter.value.length === 0)
        && !filter.selectAll && !filter.fieldValue
      ) {
        this.$emit('clearFilter', filter)
      } else {
        this.$emit('changeIncludeEmpty', filter)
      }
    },
    hideFilter (filter) {
      return !filter.fieldValue && !(filter.value && filter.value.length) && filter.selectAll && filter.includeEmpty
    },
    getPredicateText (predicate) {
      switch (predicate) {
        case 'EQUAL':
          return ''
        case 'MORE':
          return '>'
        case 'LESS':
          return '<'
        default:
          return this.$t(predicate)
      }
    },
    getFieldValueFilterString (filter) {
      if (filter.predicate === 'LIKE' || filter.predicate === 'EQUAL' && filter.header.fieldType === 'NUMBER') {
        return `${this.getPredicateText(filter.predicate)} "${filter.fieldValue}"`
      } else {
        return `${this.getPredicateText(filter.predicate)} ${filter.fieldValue}`
      }
    },
    getValueFilterString (filter, value) {
      if (filter.header.fieldType === 'BOOLEAN') {
        return `${value ? 'Истина' : 'Ложь'}`
      } else {
        return value
      }
    },
    getValueFilterColor (filter) {
      if (filter.header.fieldType === 'BOOLEAN') {
        return 'primary'
      } else {
        return filter.selectAll ? 'error' : 'primary'
      }
    },
    getEmptyFilterString (filter) {
      return filter.selectAll ? 'Исключить пустые' : 'Включить пустые'
    },
    showExcludeAllText (filter) {
      return !filter.selectAll && (!filter.value || !filter.value.length) && !filter.fieldValue && !filter.includeEmpty
    },
    setPreset (preset) {
      this.selectedPreset = preset
    },
    clearPreset () {
      this.selectedPreset = null
      this.$emit('clearAllFilters')
    },
    savePreset () {
      this.$emit('savePreset', this.selectedPreset)
    }
  }
}
</script>

<style>
.et-filter-card {
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>
