<template>
  <v-select
    :append-icon="value ? 'mdi-close' : 'mdi-menu-down'"
    :class="hasChanged ? 'changed-et-item' : ''"
    :clearable="!readonly"
    :items="selectItems"
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    class="mb-3 full-width"
    dense
    hide-details
    item-text="value"
    item-value="value"
    outlined
    @change="val => $emit('update:value', val)"
    @click:append="value ? clear() : null"
  >
    <template v-slot:prepend-item>
      <v-text-field
        v-model="inputValue"
        :readonly="readonly"
        class="mx-3 pt-3 mb-2"
        clearable
        dense
        hide-details
        solo
      />
    </template>
  </v-select>

</template>

<script>
export default {
  name: 'ListEtItem',
  props: {
    value: null,
    header: Object,
    tableId: Number,
    hasChanged: Boolean,
    readonly: Boolean
  },
  data () {
    return {
      selectItems: [this.value],
      inputValue: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.updateAutocomplete()
    },
    updateAutocomplete () {
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.inputValue
        })
        .then(({ data }) => {
          if (this.value && !data.includes(el => el.value === this.value)) {
            this.selectItems = [this.value, ...data]
          } else {
            this.selectItems = data
          }
        })
        .catch(e => console.error(e))
    },
    clear () {
      this.$emit('update:value', null)
    }
  },
  watch: {
    inputValue () {
      this.updateAutocomplete()
    }
  }
}
</script>
