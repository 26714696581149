<template>
  <v-dialog v-if="dialog" v-model="dialog" scrollable style="z-index: 1001" width="600">
    <v-card max-height="90vh">
      <v-card-title v-if="!loadingFeature" class="title pb-0">
        {{ name || type }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!loadingFeature">
        <zws-feature-map-widget
          :feature="feature"
          :layer="feature.layer"
        />
        <zws-action-toolbar
          :actions="actions"
          :feature="feature"
          @print="print"
        />
        <v-list dense>
          <template v-for="data in arrayValues">
            <v-list-item @click="$refs.htmlViewDialog.open(data)">
              <v-list-item-avatar>
                <v-icon>people</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="data in urlValues">
            <v-list-item @click="openLink(data.url)">
              <v-list-item-avatar>
                <v-icon>link</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.url }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="data in memoValues">
            <v-list-item @click="$refs.htmlViewDialog.open(data)">
              <v-list-item-avatar>
                <v-icon>description</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="data in htmlValues">
            <v-list-item @click="$refs.htmlViewDialog.open(data)">
              <v-list-item-avatar>
                <v-icon>web</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="data in bloblistValues">
            <v-list-item @click="$refs.photoListDialog.open(data, feature)">
              <v-list-item-avatar>
                <v-icon>image</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="data in blobValues">
            <v-list-item @click="$refs.photoDialog.open(feature, data)">
              <v-list-item-avatar>
                <v-icon>image</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <div>
          <v-card-actions class="px-0 mb-3" style="height: 60px; !important;">
            <v-select
              v-model="query"
              :items="feature.queries"
              :loading="loading"
              dense
              hide-details
              item-text="name"
              return-object
              solo
              @change="makeQuery"/>
            <v-text-field
              v-model="searchField"
              :placeholder="$t('filter')"
              append-icon="mdi-filter"
              class="ml-2"
              dense
              hide-details
              solo/>
          </v-card-actions>

          <div id="print">
            <zws-fields :feature="feature" :read-only-mode="!isAuthenticated"/>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
          :loading="true"
          height="350px"
          type="card"
        />
      </v-card-text>
      <v-card-actions class="elevation-1">
        <v-spacer/>
        <v-btn
          v-if="setAbility('MODERATOR') && !loadingFeature"
          :loading="saveLoading"
          color="primary"
          outlined
          @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <html-view-dialog ref="htmlViewDialog"/>
    <photo-list-dialog ref="photoListDialog"/>
    <zws-photo-dialog ref="photoDialog"/>
    <list-view-dialog ref="listViewDialog"/>

  </v-dialog>
</template>

<script>

import messages from '@/componet-locale/zws-feature-dialog/messages'
import SearchDataDialog from '@/components/utils/SearchDataDialog'
import { issueMixin } from '@/mixins/issue-mixin'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import zwsCommandBuilder from '@/services/zws-command-builder'
import projectMessages from '@/shared-locale/project/messages'
import HtmlViewDialog from '@/components/map/helpers/HtmlViewDialog'
import PhotoListDialog from '@/components/map/helpers/PhotoListDialog'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog'
import ZwsActionToolbar from '@/components/map/zws/ZwsActionToolbar'
import ZwsPhotoDialog from '@/components/map/helpers/ZwsPhotoDialog.vue'
import ZwsFeatureMapWidget from '@/components/map/zws/ZwsFeatureMapWidget.vue'
import { ATTACHED_DATA, GEOMETRY, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import ListViewDialog from '@/components/map/helpers/ListViewDialog.vue'
import { EventBus } from '@/event-bus'
import ZwsFields from '@/components/map/zws/ZwsFields.vue'

export default {
  name: 'ZwsFeatureDialog',
  components: {
    ZwsFields,
    ListViewDialog,
    ZwsFeatureMapWidget,
    ZwsPhotoDialog,
    ZwsActionToolbar,
    AbstractDataDetailsDialog,
    PhotoListDialog,
    HtmlViewDialog,
    SearchDataDialog
  },
  mixins: [issueMixin, displayServiceMixin],
  data: () => ({
    dialog: false,
    loading: false,
    query: null,
    searchField: '',
    feature: null,
    loadingFeature: false,
    saveLoading: false
  }),
  i18n: { messages, sharedMessages: projectMessages },
  methods: {
    async open (feature) {
      this.feature = JSON.parse(JSON.stringify(feature))
      this.query = this.feature.queries.length
        ? this.feature.queries.find(it => it.name === this.feature.activeQuery) || this.feature.queries[0]
        : null
      this.dialog = true
    },
    async openById (layer, id) {
      this.loadingFeature = true
      this.dialog = true
      await zwsCommandBuilder.getElemsByID(layer, [id])
        .then(features => {
          this.feature = features[0]
          this.query = this.feature.queries.length
            ? this.feature.queries.find(it => it.name === this.feature.activeQuery) || this.feature.queries[0]
            : null
        })
        .finally(() => this.loadingFeature = false)
    },
    close () {
      this.dialog = false
      this.loading = false
      this.query = null
      this.searchField = ''
      this.feature = null
    },
    async makeQuery () {
      this.loading = true
      let { props } = await ZwsCommandBuilder.getElemsByID(this.feature.layer, [this.sys], this.query)
      this.feature.props = props
      this.loading = false
    },
    async save () {
      this.saveLoading = true
      ZwsCommandBuilder.updateElemAttributes(this.feature)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.saveLoading = false)
    },
    openLink (url) {
      window.open(url, '_blank').focus()
    },
    print () {
      const prtHtml = this.filteredSimpleValues.map(prop => {
        if (prop.isList) {
          let value = ''
          if (prop.value) {
            value = prop.list.find(it => it.code === prop.value).value
          }
          return `<div><b>${prop.username}: ${value}</b></div>`
        } else {
          return `<div><b>${prop.username}: </b>${prop.value}</div>`
        }
      }).join('')

      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }
  },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      }
    },
    type () {
      let type = this.feature.layer.typeList
        && this.feature.layer.typeList.find(type => type.typeId === this.feature.typeId)
      return type && type.name || 'Object'
    },
    arrayValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'darray') return true
      })
    },
    urlValues () {
      let list = this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'durl') return true
      })
      if (list[0] && list[0].value) return JSON.parse(list[0].value)
    },
    memoValues () {
      return this.feature.props.filter(it => it.type === 'memo')
    },
    htmlValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'dhtml') return true
      })
    },
    blobValues () {
      return this.feature.props.filter(it => it.type === 'blob' && it.url)
    },
    bloblistValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'dbloblist') return true
      })
    },
    simpleValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (it.type === 'memo') return false
        if (!['dphoto', 'darray', 'dhtml', 'dbloblist', 'durl'].includes(type)) return true
      })
    },
    filteredSimpleValues () {
      return this.simpleValues
        .filter(item => item.username.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1)
    },
    sys () {
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    },
    actions () {
      return [
        OPEN_ISSUES,
        ATTACHED_DATA,
        SHARE,
        {
          title: 'button.print',
          icon: 'print',
          color: 'primary',
          background: 'white',
          onlyAuthenticated: true,
          action: 'print',
          border: '1px solid var(--v-primary-base)'
        },
        GEOMETRY,
        REMOVE
      ]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    }
  }
}
</script>
