<template>
  <div class="d-flex align-center py-2 px-1">
    <div v-if="historyMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            outlined
            @click="$emit('changeHistoryMode')"
            v-on="on"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t('button.back') }}
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.back') }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            color="primary"
            outlined
            @click="$emit('edit')"
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
            {{ $t('button.edit') }}
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.edit') }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="editMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            color="primary"
            outlined
            @click="$emit('save')"
            v-on="on"
          >
            <v-icon>mdi-content-save</v-icon>
            {{ $t('button.save') }}
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.save') }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-1 et-toolbar-btn"
            color="grey darken-1"
            text
            @click="$emit('setParent')"
            v-on="on"
          >
            <v-icon :size="28">
              mdi-file-tree
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('setParent') }}</span>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-1 et-toolbar-btn"
            color="grey darken-1"
            text
            @click="$emit('changeHistoryMode')"
            v-on="on"
          >
            <v-icon :size="28">
              mdi-history
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.history') }}</span>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-1 et-toolbar-btn"
            color="grey darken-1"
            text
            @click="$emit('share')"
            v-on="on"
          >
            <v-icon :size="28">
              mdi-share-variant-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.share') }}</span>
      </v-tooltip>
    </div>
    <v-spacer/>
    <div v-if="editMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            color="black"
            outlined
            @click="$emit('edit')"
            v-on="on"
          >
            <v-icon>mdi-cancel</v-icon>
            {{ $t('button.cancel') }}
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.cancel') }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            color="primary"
            outlined
            @click="$emit('createNewArticle')"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t('button.create') }}
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.create') }}</span>
        </template>
      </v-tooltip>
    </div>
    <div v-if="viewMode">
      <v-tooltip bottom open-delay="1500">
        <template v-slot:activator="{on}">
          <v-btn
            class="mx-1 px-1"
            color="red"
            outlined
            @click="$emit('remove')"
            v-on="on"
          >
            {{ $t('button.remove') }}
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.remove') }}</span>
        </template>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import messages from '@/componet-locale/wiki/messages'

export default {
  name: 'WikiToolbar',
  i18n: { messages },
  props: {
    editMode: Boolean,
    historyMode: Boolean
  },
  computed: {
    viewMode () {
      return !this.editMode && !this.historyMode
    }
  }
}
</script>
