<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
  >
    <v-list-item :ripple="false" class="py-1" dense style="min-height: 1px" @click="">
      <v-list-item-avatar class="ma-0 col-first" style="align-self: stretch">
        <user-avatar v-if="!dense" :only-container="messageOnly" :user="message.author"/>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0">
        <v-list-item-title
          v-if="!messageOnly"
          class="px-0 blue--text text--darken-2 font-weight-bold"
        >
          {{ getUserFullName(message.author) }}
          <span v-if="dense" class="caption grey--text">{{ getMessageTime() }}</span>
        </v-list-item-title>
        <component
          :is="dComponent"
          :message="message"
        />
      </v-list-item-content>
      <v-list-item-action v-if="message.messageKey === 'DEFAULT'" class="ma-0"
                          style="align-items: center; justify-content: center">
        <span v-if="!dense" class="caption grey--text">{{ getMessageTime() }}</span>
        <v-slide-y-transition>
          <span v-if="hover" style="position:absolute; top:-5px; right: 10px">
            <v-btn-toggle dense>
              <v-btn
                small
                @click="remove"
              >
                <v-icon color="red">delete</v-icon>
              </v-btn>
            </v-btn-toggle>
          </span>
        </v-slide-y-transition>
        <span/>
      </v-list-item-action>
      <confirmation-dialog
        ref="deleteConfirmationDialog"
        title="Вы действительно хотите удалить это сообщение"
        @confirm="confirmRemove"
      />
    </v-list-item>
  </v-hover>
</template>

<script>
import moment from 'moment'
import FileMessageContent from '@/components/chats/FileMessageContent'
import DefaultMessageContent from '@/components/chats/DefaultMessageContent'
import { messageDateTime } from '../chat-utils'
import UserAvatar from '@/components/utils/UserAvatar'
import EventMessageTemplateWrapper from '@/components/chats/messages/event-templates/EventMessageTemplateWrapper'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'

export default {
  name: 'Message',
  components: { ConfirmationDialog, UserAvatar },
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dense: {
      type: Boolean,
      default: false
    },
    messageOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
    },
    resolveMessage () {
      const eventMessageTypes = ['JOINED', 'LEFT', 'INVITED_BY', 'REMOVED_BY', 'RENAMED', 'SYSTEM']
      const mask = /^{"file/
      if (mask.test(this.message.decryptedBody)) {
        return FileMessageContent
      } else if (eventMessageTypes.includes(this.message.messageKey)) {
        return EventMessageTemplateWrapper
      } else {
        return DefaultMessageContent
      }
    },
    getMessageTime () {
      return messageDateTime(this.message.createdAt)
    },
    remove () {
      this.$refs.deleteConfirmationDialog.open(this.message)
    },
    confirmRemove () {
      this.$emit('remove', this.message)
    }
  },
  computed: {
    dComponent () {
      return this.resolveMessage()
    },
    time () {
      return moment(this.message.createdAt).format('HH:mm')
    }
  }
}
</script>

<style>
.vertical {
  margin-right: 10px;
  width: 4px;
  border-radius: 4px;
  background: lightblue;
}

.message-body {
  white-space: pre-wrap;
}

.message-body {
  user-select: text;
}

.message-body div {
  white-space: normal;
}
</style>
