<template>
  <v-dialog
    v-model="dialog"
    width="350">
    <v-card>
      <v-card-title primary-title>
        {{ $t('button.share') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot/>
        <qriously v-if="url" :size="300" :value="url.toString()"/>

        <v-text-field
          id="copy_input"
          v-model="url"
          :readonly="true"
          append-outer-icon="content_copy"
          class="mt-4"
          dense
          hide-details
          outlined
          @click:append-outer="copy"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/share-data-dialog/messages'
import Vue from 'vue'
import VueQriously from 'vue-qriously'

Vue.use(VueQriously)

export default {
  name: 'ShareDataDialog',
  data: () => ({
    dialog: false,
    url: null
  }),
  i18n: { messages },
  methods: {
    share (url) {
      this.url = url || window.location
      this.copy()
      this.dialog = true
    },
    copy () {
      navigator.clipboard.writeText(this.url)
        .then(() => EventBus.$emit('showInfoMessage', this.$t('linkCopied')))
        .catch(() => console.error("Link copy failed"))
    }
  }
}
</script>
