<template>
  <v-dialog v-if="dialog" v-model="dialog" width="1100px">
    <v-card class="pa-0 doc-view-panel" height="90vh">
      <issue-view
        v-if="issueId"
        :init-issue-id="issueId"
        dialog-mode
        @close="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import IssueView from '@/components/issue/IssueView.vue'

export default {
  name: 'IssueViewDialog',
  components: { IssueView },
  data: () => ({
    dialog: false,
    issueId: null
  }),
  methods: {
    open (issueId) {
      this.issueId = issueId
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.issueId = null
    }
  }
}
</script>
