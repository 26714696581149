<template>
  <v-list>
    <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtEditQueryDialog')">
      <v-list-item-avatar>
        <v-icon>
          mdi-database-edit
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ $t('editTable') }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtViewSettingsDialog')">
      <v-list-item-avatar>
        <v-icon>mdi-table-eye</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ $t('viewSettings') }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="setAbility('SYSTEM_ADMIN')" dense @click="EventBus.$emit('openEtToLayerDlg')">
      <v-list-item-avatar>
        <v-icon>mdi-map-search</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ $t('linkingToLayerObjects') }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item dense @click="EventBus.$emit('openEtActionSettingsDialog')">
      <v-list-item-avatar>
        <v-icon>mdi-view-dashboard-edit-outline</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ $t('actionSettings') }}
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et/messages'

export default {
  name: 'EtSettingsBar',
  i18n: { messages },
  computed: {
    EventBus () {
      return EventBus
    }
  }
}
</script>
