<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="pa-2">Выберите родительскую статью</v-card-title>
      <v-card-text class="pa-2">
        <v-autocomplete
          v-model="parentArticle"
          :items="unrelatedArticlesLastVersion"
          :label="$t('selectArticle')"
          :placeholder="$t('rootByDefault')"
          :search-input.sync="search"
          class="my-2"
          clearable
          dense
          hide-details
          hide-no-data
          item-text="title"
          item-value="article"
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined @click="close">{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="setParentArticle">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/wiki/messages'

export default {
  name: 'SetParentArticleDialog',
  i18n: { messages },
  data: () => ({
    dialog: false,
    article: null,
    search: '',
    unrelatedArticlesLastVersion: [],
    parentArticle: null
  }),
  methods: {
    open (article) {
      this.article = article
      this.search = ''
      this.parentArticle = null
      this.dialog = true
      this.getUnrelatedArticlesLastVersion()
    },
    close () {
      this.dialog = false
    },
    getUnrelatedArticlesLastVersion () {
      this.$axios
        .get('article/get-unrelated-articles', {
          params: {
            title: this.search,
            node: this.article.id
          }
        })
        .then(response => {
          this.unrelatedArticlesLastVersion = response.data
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          this.close()
        })
    },
    setParentArticle () {
      const parentId = this.parentArticle && this.parentArticle.id
      const childId = this.article.id
      this.$axios
        .get('article/set-parent', {
          params: { parentId, childId }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t(parentId ? 'parentWasSet' : 'parentWasUnset'))
          this.$emit('save', this.article.id)
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  }
}
</script>
