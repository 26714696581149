<template>
  <div>
    <v-simple-table
      disable-sort
      hide-default-footer
    >
      <thead>
      <tr>
        <td class="text-center bold">{{ $t('version') }}</td>
        <td class="text-center bold">{{ $t('name') }}</td>
        <td class="text-center bold">{{ $t('publicationDate') }}</td>
        <td class="text-center bold">{{ $t('publishedBy') }}</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="version in versions">
        <td class="text-center"><a @click="showVersion(version)">{{ version.version }}</a></td>
        <td class="text-center">{{ version.title }}</td>
        <td class="text-center">{{ formatDate(version.updatedAt) }}</td>
        <td class="text-center">{{ getUserFullName(version.updatedBy) }}</td>
        <td class="text-center">
          <v-tooltip bottom open-delay="1000">
            <template #activator="{on}">
              <v-btn icon @click="compare(version)" v-on="on">
                <v-icon>
                  mdi-file-compare
                </v-icon>
              </v-btn>
            </template>
            {{ $t('compare') }}
          </v-tooltip>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <wiki-html-viewer
      ref="htmlViewer"
    />

  </div>
</template>

<script>
import messages from '@/componet-locale/wiki/messages'
import { EventBus } from '@/event-bus'
import WikiVersionsDiffDialog from '@/components/wiki/WikiHtmlViewer.vue'
import WikiHtmlViewer from '@/components/wiki/WikiHtmlViewer.vue'
import { visualDomDiff } from 'visual-dom-diff'

export default {
  name: 'WikiArticleHistory',
  i18n: { messages },
  components: { WikiHtmlViewer, WikiVersionsDiffDialog },
  props: {
    selectedVersion: Object
  },
  data: () => ({
    versions: [],
    historyItems: []
  }),
  mounted () {
    this.getAllVersions()
  },
  methods: {
    getAllVersions () {
      this.$axios
        .get(`article/get-all-versions/${this.selectedVersion.article.id}`)
        .then(res => this.versions = res.data)
        .catch(() => EventBus.$emit('showErrorMessage'))
    },
    async loadVersionContent (version) {
      return this.$axios.get(`article/get-article-version/${version.id}`)
        .then(res => res.data.content)
    },
    async compare (version) {
      this.loadVersionContent(version)
        .then(content => {
          if (!content) return EventBus.$emit('showInfoMessage', this.$t('emptyVersionContent'))
          const parser = new DOMParser()
          let originalNode = parser.parseFromString(content, 'text/html')
          let changedNode = parser.parseFromString(this.selectedVersion.content, 'text/html')
          let diff = visualDomDiff(originalNode, changedNode)
          this.$refs.htmlViewer.open(new XMLSerializer().serializeToString(diff))
        })
        .catch(() => EventBus.$emit('showErrorMessage'))
    },
    showVersion (version) {
      this.loadVersionContent(version)
        .then(content => {
          this.$refs.htmlViewer.open(content)
        })
        .catch(() => EventBus.$emit('showErrorMessage'))
    }
  }
}
</script>
