<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('button.attachedData') }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!loading" class="pa-0">
        <v-list v-if="attachedData" dense subheader>
          <template v-for="(data, index) in attachedData" v-if="isAuthenticated">
            <v-list-item :key="`data-${index}`" @click="showData(data)">
              <v-list-item-avatar>
                <v-icon>{{ getUrlByDataType(data.dataType).icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="blue--text">{{ data.project.name }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click.native="removeRelatedData(data, $event)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <v-list v-if="attachedFiles">
          <v-list-item v-for="file in attachedFiles" dense @click="">
            <v-list-item-action
              class="mr-4"
              @click="viewFile(file)"
            >
              <v-img
                :src="`/image/${file.type}.png`"
                class="mx-auto"
                height="40"
                width="35">
              </v-img>
            </v-list-item-action>

            <v-list-item-content
              @click="viewFile(file)"
            >
              <div>
                {{ file.name }}
              </div>
              <v-list-item-subtitle>
                {{ getUserFullName(file.owner) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="caption">{{ formatDate(file.creationDate) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon outliend @click="openRemoveFileConfirmationDialog(file)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card
          v-if="!(attachedData && attachedData.length) && !(attachedFiles && attachedFiles.length)"
          class="mx-2 mb-2"
          outlined
        >
          <v-card-text>
            {{ $t('noAttachments') }}
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader
          :loading="loading"
          type="text@2"
        />
      </v-card-text>
      <v-card-actions v-if="isAuthenticated">
        <v-btn
          v-if="setAbility('MODERATOR')"
          outlined
          @click="$refs.searchDataDialog.open()">
          <v-icon>attach_file</v-icon>
          {{ $t('linkDataFromSystem') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          v-if="setAbility('MODERATOR')"
          color="primary"
          outlined
          @click="addFile"
        >
          <v-icon>add</v-icon>
          {{ $t('addFile') }}
        </v-btn>
        <v-btn
          v-if="setAbility('MODERATOR')"
          color="primary"
          outlined
          @click="createDocument"
        >
          <v-icon class="mr-1">mdi-file-document-plus-outline</v-icon>
          {{ $t('createDocument') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <search-data-dialog ref="searchDataDialog" @select="data => attachData([data.id])"/>
    <new-file-dialog
      ref="newFileDialog"
      :dataModifier="dataModifier"
      :external-storage="{
        saveFileURL: '/files/save-attached-data',
        saveRecordsURL: 'files/save-attached-data-records'
      }"
      @on-save="loadAttachedFiles"
    />
    <file-viewer
      ref="fileViewer"
      :storage-url="fileEntity => `files/get-attached-file/${fileEntity.id}`"
    />
    <confirmation-dialog
      ref="removeFileConfirmationDialog"
      :title="$t('wantToRemove')"
      @confirm="removeFile"
    />
    <doc-upload-dialog
      @onSave="attachData"
      ref="docUploadDialog"
    />
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/attached-data/messages'
import sharedMessages from '@/componet-locale/lp-file-attachments/messages'
import { EventBus } from '@/event-bus'
import SearchDataDialog from '@/components/utils/SearchDataDialog.vue'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog.vue'
import NewFileDialog from '@/components/drive/NewFileDialog.vue'
import FileViewer from '@/components/drive/FileViewer.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import DocUploadDialog from '@/components/doc/DocUploadDialog.vue'

export default {
  name: 'ZwsFeatureAttachedDataDialog',
  components: {
    DocUploadDialog,
    ConfirmationDialog,
    FileViewer,
    NewFileDialog,
    AbstractDataDetailsDialog,
    SearchDataDialog
  },
  i18n: { messages, sharedMessages },
  data: () => ({
    dialog: false,
    loading: false,
    feature: null,
    attachedData: null,
    attachedFiles: null
  }),
  methods: {
    async init () {
      this.loading = true
      await this.loadAttachedData()
      await this.loadAttachedFiles()
      this.loading = false
    },
    async loadAttachedData () {
      return this.$axios
        .get('layer/get-attached-data', {
          params: {
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(res => {
          this.attachedData = res.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    loadAttachedFiles () {
      if (!this.isAuthenticated) return
      return this.$axios
        .get('files/get-attached-files', {
          params: {
            zwsFeatureId: this.layerIdWithFeatureId
          }
        })
        .then(response => {
          this.attachedFiles = response.data
          this.$emit('got-attached-files', response.data)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    dataModifier (data) {
      if (this.feature) {
        data.zwsFeatureId = this.layerIdWithFeatureId
      }
    },
    viewFile (file) {
      this.$refs.fileViewer.open(file)
    },
    openRemoveFileConfirmationDialog (file) {
      this.$refs.removeFileConfirmationDialog.open(file)
    },
    removeFile (file) {
      this.$axios
        .delete('files/attached-file', {
          params: {
            id: file.id
          }
        })
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('fileRemoved'))
          this.loadAttachedFiles()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('removeError')))
    },
    open (feature) {
      this.feature = feature
      this.dialog = true
      this.init()
    },
    close () {
      this.dialog = false
    },
    showData (data) {
      EventBus.$emit('showData', data)
    },
    removeRelatedData (item, event) {
      event.stopPropagation()
      if (!this.sys) return
      this.$axios
        .get('/layer/unbind-data-to-feature', {
          params: {
            abstractDataId: item.id,
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.init())
    },
    attachData (ids) {
      if (!this.sys) return
      this.$axios
        .get('/layer/bind-data-to-feature', {
          params: {
            abstractDataIds: ids.join(','),
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.init())
    },
    addFile () {
      this.$refs.newFileDialog.open()
    },
    createDocument(){
      this.$refs.docUploadDialog.open()
    }
  },
  computed: {
    sys () {
      let key = this.feature.props.find(prop => prop.isKey)
      if (key && key.value) {
        return key.value
      }
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    },
    layerIdWithFeatureId () {
      return `${this.feature.layer.layerId}:${this.feature.elemId}`
    }
  }
}
</script>
