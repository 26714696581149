<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('confirmation') }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ $t('fileRemovalWarningText') }}: {{ files.length }}
        </div>
        <div v-if="sharedFiles.length" class="red--text">
          {{ $t('sharedFilesRemovalWarning') }}: {{ sharedFiles.length }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          @click="dialog = false"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          color="red"
          outlined
          @click="confirm"
        >
          {{ $t('button.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/drive/messages'

export default {
  name: 'FileRemovalConfirmationDialog',
  i18n: { messages },
  data: () => ({
    dialog: false,
    files: []
  }),
  methods: {
    confirm () {
      this.$emit('confirm', this.files)
      this.dialog = false
    },
    open (files) {
      this.files = files
      this.dialog = true
    }
  },
  computed: {
    sharedFiles () {
      return this.files.filter(it => it.isShared)
    }
  }
}
</script>
