<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ name || type }}
    </template>
    <template v-slot:info>
      <zws-action-toolbar
        :actions="actions"
        :feature="feature"
        :name="name || type"
        class="px-3 mt-1 mb-2"
        @close="$emit('close')"
      />
    </template>

    <template v-slot:fields>
      <div class="mb-3">
        <v-list-item v-for="data in blobValues" dense @click="$refs.photoDialog.open(feature, data)">
          <v-list-item-avatar>
            <v-icon>image</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.username }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <zws-fields :feature="feature" :read-only-mode="!isAuthenticated"/>
    </template>

    <template v-slot:actions>
      <v-btn
        outlined
        @click="$refs.zwsFeatureDialog.open(feature)"
      >
        <v-icon left>description</v-icon>
        Детали
      </v-btn>
      <v-spacer/>
      <v-btn
        v-if="setAbility('MODERATOR')"
        :loading="saveLoading"
        color="primary"
        outlined
        @click="save"
      >
        {{ $t('button.save') }}
      </v-btn>

      <zws-photo-dialog ref="photoDialog"/>
      <zws-feature-dialog ref="zwsFeatureDialog"/>
    </template>
  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import ZwsFields from '@/components/map/zws/ZwsFields'
import ZwsActionToolbar from '@/components/map/zws/ZwsActionToolbar'
import { ATTACHED_DATA, EDIT, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import { EventBus } from '@/event-bus'
import PhotoListDialog from '@/components/map/helpers/PhotoListDialog.vue'
import ZwsPhotoDialog from '@/components/map/helpers/ZwsPhotoDialog.vue'

export default {
  name: 'ZwsFeatureCard',
  components: {
    ZwsPhotoDialog,
    PhotoListDialog,
    ZwsActionToolbar,
    ZwsFields,
    ZwsFeatureDialog: () => import('@/components/map/zws/ZwsFeatureDialog'),
    FeatureCard
  },
  data: () => ({
    saveLoading: false
  }),
  props: { feature: Object },
  methods: {
    async save () {
      this.saveLoading = true
      ZwsCommandBuilder.updateElemAttributes(this.feature)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.saveLoading = false)
    },
    close () {
      this.$emit('close')
    }
  },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      } else return null
    },
    type () {
      if (!this.feature.layer || !this.feature.layer.typeList) return ' - '
      let type = this.feature.layer.typeList
        .find(type => type.typeId === this.feature.typeId)
      return type ? type.name : 'Object'
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    blobValues () {
      return this.feature.props.filter(it => it.type === 'blob' && it.url)
    }
  }
}
</script>
