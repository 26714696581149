<template>
  <v-text-field
    :class="hasChanged ? 'changed-et-item' : ''"
    :clearable="!readonly"
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    auto-grow
    class="mb-3"
    dense
    hide-details
    outlined
    row-height="1"
    type="number"
    @input="val => $emit('update:value', val)"
  />
</template>

<script>
export default {
  name: 'NumberEtItem',
  props: {
    value: [Number, String],
    header: Object,
    hasChanged: Boolean,
    readonly: Boolean
  }
}
</script>
