<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card class="d-flex flex-column" height="70vh">
      <v-card-title>{{ $t('selectedToRemove') }}: {{ selectedIds.length }}</v-card-title>
      <v-card-text class="flex-grow-1 overflow-y-auto">
        <v-treeview
          v-model="selectedIds"
          :items="articlesTree"
          dense
          item-key="node.id"
          item-text="node.currentTitle"
          open-all
          selectable
          selection-type="independent"
          transition
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="black" outlined @click="close">{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
          :disabled="selectedIds.length === 0"
          color="red"
          outlined
          @click="remove">
          {{ $t('button.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/wiki/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'WikiRemoveDialog',
  i18n: { messages },
  props: {
    articlesTree: Array
  },
  data: () => ({
    dialog: false,
    selectedIds: []
  }),
  methods: {
    open (article) {
      if (article) this.selectedIds = [article.id]
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.selectedIds = []
    },
    remove () {
      this.$axios
        .post(`article/delete-by-ids`, this.selectedIds)
        .then(() => {
          EventBus.$emit('showInfoMessage', this.$t('articlesRemoved'))
          this.$emit('remove')
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage'))
    }
  }
}
</script>
