<template>
  <div>
    <v-list v-if="et.presets && et.presets.length > 0">
      <v-list-item v-for="preset in et.presets" @click="$emit('setPreset', preset)">
        <v-list-item-title>{{ preset.name }}</v-list-item-title>
        <v-menu>
          <template #activator="{on}">
            <v-list-item-action v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </template>
          <v-list>
            <v-list-item @click="$emit('updatePreset', preset)">
              <v-list-item-action class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('button.edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$emit('removePreset', preset)">
              <v-list-item-action class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ $t('button.remove') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
    <div v-else class="ml-1 my-2">
      {{ $t('noPresets') }}
    </div>
  </div>
</template>

<script>
import messages from '@/componet-locale/et/messages'

export default {
  name: 'EtPresetBar',
  i18n: { messages },
  props: {
    et: Object
  }
}
</script>
