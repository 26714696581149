<template>
  <v-dialog v-if="dialog" v-model="dialog" width="600">
    <v-card>
      <v-card-title>{{ $t('massEditing') }}</v-card-title>
      <v-card-text class="pt-0">
        <div class="bold ma-2">
          {{ $t('itemsCount') }}: {{ itemsCount }}.
          <br>
          {{ $t('fieldsCount') }}: {{ fields.length }}.
        </div>
        <v-simple-table style="border: 1px solid black; border-radius: 5px">
          <thead>
          <tr>
            <th>{{ $t('fieldName') }}</th>
            <th>{{ $t('value') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="field in fields">
            <td>{{ field.header.fieldName }}</td>
            <td>{{ field.value }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="confirm">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/et-confirm-edit-dialog/messages'

export default {
  name: "EtConfirmEditDialog",
  i18n: { messages },
  data: () => ({
    dialog: false,
    fields: [],
    itemsCount: 0
  }),
  methods: {
    open (fields, itemsCount) {
      this.fields = fields
      this.itemsCount = itemsCount
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.fields = []
      this.itemsCount = 0
    },
    confirm () {
      this.close()
      this.$emit('confirm')
    }
  }
}
</script>
