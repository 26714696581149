<template>
  <div class="full-width overflow-y-auto" style="height: 100%">

    <div v-if="selectedVersion" class="mt-4 px-5">
      <h1>{{ selectedVersion.title || $t('noName') }}</h1>
      <div class="text--secondary caption">
        {{ $t('createdBy') }}: {{ getUserFullName(selectedVersion.article.author) }},
        {{ formatDateDIN(selectedVersion.article.createdAt) }}
      </div>
      <div class="text--secondary caption mb-5">
        {{ $t('updatedBy') }}: {{ getUserFullName(selectedVersion.updatedBy) }},
        {{ formatDateDIN(selectedVersion.updatedAt) }}
      </div>
      <div id="wiki-article-content" v-html="selectedVersion.content"/>
    </div>

  </div>
</template>

<script>
import messages from '@/componet-locale/wiki/messages'

export default {
  name: 'WikiArticleView',
  i18n: { messages },
  props: {
    selectedVersion: Object,
    loading: Boolean
  }
}
</script>

<style>
#wiki-article-content >>> table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

#wiki-article-content >>> th {
  background-color: #7b8184;
  color: #ffffff;
}

#wiki-article-content >>> table {
  border-collapse: collapse;
}

#wiki-article-content >>> th, td {
  padding: 0 5px 5px;
}
</style>
