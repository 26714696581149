<template>
  <v-dialog v-if="dialog" v-model="dialog" width="660px">
    <v-card>
      <v-card-title>
        {{ $t('settings') }}
      </v-card-title>
      <v-card-text>
        <div class="mb-2">
          <v-text-field
            v-model="group.name"
            :label="$t('groupName')"
            dense
            hide-details
            outlined
          />
        </div>
        <div class="d-flex">
          <div class="mr-4">
            {{ $t('strokeColor') }}
            <v-color-picker
              v-model="group.strokeColor"
              hide-inputs
              mode="hexa"
            />
          </div>
          <div>
            {{ $t('fillColor') }}
            <v-color-picker
              v-model="group.fillColor"
              hide-inputs
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/map-groups/messages'

export default {
  name: "MapGroupSettingsDialog",
  i18n: { messages },
  data: () => ({
    dialog: false,
    group: null
  }),
  methods: {
    open (group) {
      this.group = JSON.parse(JSON.stringify(group))
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.group = null
    },
    save () {
      this.$emit('updateGroup', this.group)
      this.close()
    }
  }
}
</script>
