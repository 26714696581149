<template>
  <div>
    <v-menu
      v-model="picker"
      :close-on-content-click="false"
      min-width="290px"
      offset-overflow
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-mask="mask"
          :class="(simpleInput ? 'simple-input ' : '') + (primaryBorder ? 'changed-et-item' : '')"
          :value="formatter(value)"
          v-bind="$attrs"
          @focusout="$emit('focusout')"
          @input="inputChanged"
          v-on="on"
          clearable
        >
          <template v-slot:append>
            <slot name="append"/>
          </template>
          <template v-slot:prepend-inner>
            <slot name="prepend-inner"/>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        :disabled="$attrs.readonly"
        :first-day-of-week="1"
        :value="pickerValue"
        class="simple-input"
        locale="ru"
        @input="datePicked"
      />
    </v-menu>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import moment from 'moment'

const viewFormat = 'DD.MM.YYYY'
const pickerFormat = 'YYYY-MM-DD'

export default {
  name: "DDatePicker",
  model: {
    props: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String],
      required: false
    },
    simpleInput: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: viewFormat
    },
    primaryBorder: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    picker: false
  }),
  directives: { mask },
  methods: {
    inputChanged (date) {
      if (date === '' || date === null) {
        this.$emit('input', '')
      } else if (moment(date, viewFormat, true).isValid()) {
        this.$emit('input', moment(date, viewFormat).format(this.format))
        this.picker = true
      }
    },
    datePicked (date) {
      this.$emit('input', moment(date, pickerFormat).format(this.format))
      this.$emit('picked')
      this.picker = false
    },
    formatter (date) {
      const dateValue = moment(date, this.format)
      return dateValue.isValid() ? dateValue.format(viewFormat) : ''
    }
  },
  computed: {
    mask () {
      return viewFormat.replaceAll(/[a-z]/gi, '#')
    },
    pickerValue () {
      if (moment(this.value, this.format, true).isValid()) {
        return moment(this.value, this.format).format(pickerFormat)
      } else {
        return ''
      }
    }
  }
}
</script>
