export const filterMixin = {
  data: () => ({
    filters: [
      {
        name: 'name',
        type: 'text-field-filter',
        label: 'search',
        model: {
          value: ''
        },
        main: true
      },
      {
        name: 'categoryList',
        type: 'category-picker',
        label: 'categories',
        model: {
          color: 'deep-purple ',
          value: [],
          urlDataSource: '/category/get-all-for-project/',
          itemText: 'name'
        },
        main: true
      },
      {
        name: 'keywords',
        type: 'combo-box-filter',
        label: 'keyWords',
        model: {
          color: 'primary',
          value: [],
          urlDataSource: '/keyword/get-all-for-project/',
          itemText: 'value'
        },
        main: false
      },
      {
        name: 'provider',
        type: 'combo-box-filter',
        label: 'source',
        model: {
          color: 'blue-grey darken-2',
          value: [],
          urlDataSource: '/provider/get-all-for-project/',
          itemText: 'name'
        },
        main: false
      }
    ],
    sorting: {
      label: 'sorting',
      model: {
        value: {
          sortBy: 'name',
          desc: 'ASC'
        }
      },
      main: false,
      isNotFilter: true
    }
  })
}
