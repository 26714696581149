<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ feature.name || name }}
    </template>
    <template v-slot:subtitle>
      {{ feature.template.name }}
      <br/>
      <span v-if="geometryInfoInfo" v-html="geometryInfoInfo"/>
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        :counters="counters"
        class="px-3 mt-1 mb-2"
        @attachData="attachData"
        @calculate="calculate"
        @edit="$emit('edit')"
        @history="history"
        @openIssues="openIssues"
        @printTemplate="openPrintTemplateSelectorDialog"
        @remove="openConfirmationDialog"
        @share="share"
      />
      <layer-poi-dialog
        ref="layerPoiDlg"
        actions-toolbar
        @update="refresh"
      />
      <layer-poi-changelog-dialog ref="changelogDlg"/>
      <layer-poi-issues-dialog
        ref="issuesDialog"
        @on-save-issue="updateCounter"
      />
      <lp-attached-data ref="attachedDataDlg" :lp="feature" @got-attached-files="setAttachedFiles"
                        @data-updated="updateCounter"/>
      <share-data-dialog ref="shareDataDialog"/>
      <confirmation-dialog
        ref="removeConfirmationDialog"
        :title="$t('deleteConfirmation')"
        @confirm="remove"
      />
    </template>
    <template v-slot:tabs>
      <v-tabs
        v-model="tab"
        bg-color="primary"
        density="compact"
        fixed-tabs
      >
        <v-tab value="one">{{ $t('overview') }}</v-tab>
        <v-tab
          v-if="dbItemsCount > 0"
          value="two"
          @click="() => $refs.dbFields ? $refs.dbFields.back() : ''"
        >
          {{ $t('et') }}
        </v-tab>
        <v-tab v-if="images.length" value="three">{{ $t('photos') }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:fields>
      <v-tabs-items v-model="tab" style="position: relative">
        <v-tab-item class="pt-3">
          <layer-poi-fields
            :poi="feature"
            :readonly-mode="!isAuthenticated"
            @refresh="refresh"
          />
        </v-tab-item>

        <v-tab-item v-if="feature.relatedInfoInDB && feature.relatedInfoInDB.length">
          <feature-db-fields
            ref="dbFields"
            :infoFromDb="feature.relatedInfoInDB"
            @showBackBtn="updateShowBackBtn"
          />
        </v-tab-item>

        <v-tab-item>
          <v-expand-transition>
            <lp-image-carousel :images="images" class="mb-3"/>
          </v-expand-transition>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template
      v-slot:actions
    >
      <v-tabs-items v-model="tab" class="full-width">
        <v-tab-item v-if="isAuthenticated">
          <div class="full-width d-flex flex-row">
            <v-btn
              outlined
              @click="$refs.layerPoiDlg.open(null, feature.id)"
            >
              {{ $t('details') }}
            </v-btn>
            <v-spacer/>
            <v-btn
              color="primary"
              outlined
              @click="$emit('save')"
            >
              {{ $t('save') }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="full-width d-flex flex-row">
            <v-slide-y-reverse-transition>
              <v-btn
                v-show="showBackBtn"
                outlined
                @click="() => $refs.dbFields.back()"
              >
                <v-icon>mdi-backburger</v-icon>
              </v-btn>
            </v-slide-y-reverse-transition>
          </div>
        </v-tab-item>

        <v-tab-item>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <print-template-selector-dialog
      ref="printTemplateSelector"
      @openPrintTemplateDialog="openPrintTemplateDialog"
      @print="print"
    />
    <print-template-dialog
      ref="printTemplateDialog"
    />
  </feature-card>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import FeatureCard from '@/components/map/FeatureCard'
import { EventBus } from '@/event-bus'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import { ATTACHED_DATA, EDIT, OPEN_ISSUES, PRINT_TEMPLATE, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import { getNameOfLayerPoi, getRouteToLayerPoi, saveLayerPoi } from '@/components/layer-poi/layer-poi-service'
import ActionToolbar from '@/components/utils/ActionToolbar'
import LayerPoiChangelogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'
import LpAttachedData from '@/components/layer-poi/attachments/LpAttachedData.vue'
import LayerPoiIssuesDialog from '@/components/issue/LayerPoiIssuesDialog.vue'
import { poiMixin } from '@/mixins/poi-mixin'
import messages from '@/componet-locale/layer-poi-feature-card/messages'
import sharedMessages from '@/componet-locale/feature-card/messages'
import LpImageCarousel from '@/components/map/images/LpImageCarousel.vue'
import EtItemCard from '@/components/et/EtItemCard.vue'
import FeatureDbFields from '@/components/map/FeatureDbFields.vue'
import PrintTemplateSelectorDialog from '@/components/admin/print-template/PrintTemplateSelectorDialog.vue'
import PrintTemplateDialog from '@/components/admin/print-template/PrintTemplateDialog.vue'

export default {
  name: 'LayerPoiFeatureCard',
  i18n: { messages, sharedMessages },
  components: {
    PrintTemplateDialog,
    PrintTemplateSelectorDialog,
    FeatureDbFields,
    EtItemCard,
    LpImageCarousel,
    LayerPoiIssuesDialog,
    LpAttachedData,
    LayerPoiChangelogDialog,
    ActionToolbar,
    ConfirmationDialog,
    ShareDataDialog,
    LayerPoiDialog,
    FeatureCard,
    LayerPoiFields
  },
  mixins: [mapMeasureMixin, poiMixin],
  props: {
    feature: Object
  },
  data: () => ({
    tab: null,
    attachedFiles: [],
    showBackBtn: false
  }),
  created () {
    this.updateCounter()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.feature.id }
        })
        .then(() => {
          this.refresh()
          this.close()
        })
    },
    save () {
      saveLayerPoi(this.feature)
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    openIssues () {
      this.$refs.issuesDialog.open(this.feature)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.feature)
    },
    calculate () {
      let source = new Vector({
        features: (
          new GeoJSON({
            featureProjection: 'EPSG:3857'
          }))
          .readFeatures([this.feature])
      })
    },
    share () {
      this.$refs.shareDataDialog.share(
        window.location.origin + this.$router.resolve(
          getRouteToLayerPoi(this.selectedProject.id, this.feature, this.$route.params.id)
        ).href
      )
    },
    history () {
      this.$refs.changelogDlg.open(this.selectedProject.id, this.feature)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    updateCounter () {
      this.populateCounters(this.feature.id)
    },
    setAttachedFiles (files) {
      this.attachedFiles = files
    },
    openPrintTemplateSelectorDialog () {
      this.$axios.get('/print-template/get-all-by-lp-template', {
        params: {
          lpTemplateId: this.feature.template.id
        }
      })
        .then(res => this.$refs.printTemplateSelector.open(res.data))
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    openPrintTemplateDialog (id) {
      this.$refs.printTemplateDialog.open(id, { pois: [this.feature] })
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
      let rows = []
      this.getProperties().forEach((value, key) => {
        rows.push(`<div><b>${key}: </b>${value}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    getProperties () {
      let map = new Map()

      function getValue (prop) {
        switch (prop.field.type) {
          case 'STRING':
            return prop.string
          case 'DATE':
            return prop.date
          case 'NUMBER':
            return prop.number
          case 'BOOLEAN':
            return prop.boolean
          default:
            return prop.value
        }
      }

      this.feature.properties.forEach(prop => map.set(prop.field.name, getValue(prop)))
      return map
    },
    updateShowBackBtn (v) {
      this.showBackBtn = v
    }
  },
  computed: {
    geometryInfoInfo () {
      let geometry = this.getGeometry(this.feature)
      switch (this.feature.geometry.type) {
        case 'LineString' :
        case 'MultiLineString' :
          return 'Длина объекта: ' + this.formatLength(geometry, { projection: 'EPSG:4326' })
        case 'Polygon':
        case 'MultiPolygon' :
          return 'Площадь объекта: ' + this.formatArea(geometry, { projection: 'EPSG:4326' })
      }
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, EDIT, PRINT_TEMPLATE, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    name () {
      return getNameOfLayerPoi(this.feature)
    },
    images () {
      return this.attachedFiles.filter(file => ['png', 'jpg', 'gif', 'jpeg'].includes(file.type))
    },
    dbItemsCount () {
      return this.feature.relatedInfoInDB
        ? this.feature.relatedInfoInDB.reduce((count, dbItem) => count + dbItem.props.length, 0)
        : 0
    }
  }
}
</script>
