<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500">
    <v-card>
      <v-card-text class="pa-2">
        <div
          v-for="placeholder in placeholders"
          v-if="placeholder.abstractData && placeholder.settings.selectablePreset"
          class="my-2"
        >
          <div class="subtitle-1 font-weight-bold pl-2 pb-3">
            {{ placeholder.string }}
          </div>
          <v-select
            v-if="placeholder && placeholder.abstractData.dataType === 'ET'"
            v-model="placeholder.settings.etPreset"
            :items="placeholder.abstractData.presets"
            :label="$t('preset')"
            :no-data-text="$t('noPresets')"
            class="mb-3"
            clearable
            dense
            hide-details
            item-text="name"
            outlined
            return-object
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined text @click="close">{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="emitExport">{{ $t('button.export') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/et/messages'

export default {
  name: "PreExportSettings",
  i18n: { messages },
  data: () => ({
    dialog: false,
    placeholders: null
  }),
  methods: {
    open (version) {
      this.placeholders = JSON.parse(JSON.stringify(version.placeholders))
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.placeholders = null
    },
    emitExport () {
      this.$emit('export', this.placeholders)
      this.close()
    }
  }
}
</script>
