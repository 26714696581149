<template>
  <v-card
    v-if="issues && issues.length"
    class="mt-2"
    outlined
  >
    <v-card-title class="pb-1 pt-2">
      <span class="subtitle-1 pb-1">{{ $t('issues') }}</span>
      <v-spacer/>
      <v-btn
        text
        @click="createIssue"
      >
        {{ $t('button.create') }}
      </v-btn>
      <v-btn
        text
        @click="goToIssues"
      >
        {{ $t('toIssues') }}
      </v-btn>
    </v-card-title>

    <v-list dense subheader>
      <template v-for="(issue, index) in issues">
        <v-list-item :key="`issue-${index}`" @click="openIssue(issue)">
          <v-list-item-avatar>
            <v-avatar :color="issue.status.color" class="mx-3" size="20"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ issue.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ getUserFullName(issue.performer) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <new-issue-dialog ref="newIssueDialog" :category="category" @onSave="$emit('save')"/>
    <issue-view-dialog ref="issueViewDialog"/>
  </v-card>
</template>

<script>
import messages from '@/componet-locale/issue-list/messages'
import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import IssueViewDialog from '@/components/issue/IssueViewDialog.vue'

export default {
  name: 'ShortIssueList',
  components: { IssueViewDialog, NewIssueDialog },
  i18n: { messages },
  props: {
    issues: Array,
    category: Object
  },
  methods: {
    goToIssues () {
      this.$router.push({ name: 'issue-list' })
      window.history.replaceState({ category: this.category }, '', this.$route.fullPath)
    },
    openIssue (issue) {
      this.$refs.issueViewDialog.open(issue.id)
    },
    createIssue () {
      this.$refs.newIssueDialog.open()
    }
  }
}
</script>
