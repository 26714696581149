<template>
  <v-dialog v-if="dialog" v-model="dialog" fullscreen style="z-index: 10001">
    <v-card class="d-flex flex-column" color="rgba(0,0,0,0.85)" dark height="100%" tile>
      <v-carousel v-model="currentIndex" height="100%">
        <div class="d-flex pa-3">
          <v-btn class="mr-2" icon @click="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer/>
          <portal-target name="zws-photo-download-btn"/>
        </div>
        <v-carousel-item v-for="field in blobList">
          <img-from-zulu
            ref="zuluImg"
            :blobField="field"
          />
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
import ImgFromZulu from '@/components/map/helpers/ImgFromZulu'

export default {
  name: 'ZwsPhotoDialog',
  components: { ImgFromZulu },
  data: () => ({
    dialog: false,
    feature: null,
    currentIndex: null,
    blobList: null
  }),
  methods: {
    async open (feature, initialField) {
      this.feature = feature
      this.feature.props
        .filter(it => it.type === 'blob' && it.url)
        .forEach(it => it.image = null)
      this.blobList = this.feature.props.filter(it => it.type === 'blob' && it.url)
      this.currentIndex = this.blobList.findIndex(el => el.name === initialField.name)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.feature = null
    }
  }
}
</script>
