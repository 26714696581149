<template>
  <v-dialog v-model=dialog scrollable>
    <v-card v-if="dialog" height="100vh">
      <v-card-actions>
        {{ layer.name }}
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-text class="pa-0" style="overflow: hidden">
        <layer-poi-table
          :layer-id="layer ? layer.id : null"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LayerPoiTable from '@/components/layer-poi/LayerPoiTable.vue'

export default {
  name: "LayerPoiTableDialog",
  components: { LayerPoiTable },
  data: () => ({
    dialog: false,
    layer: null
  }),
  methods: {
    open (layer) {
      this.layer = layer
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.layerId = null
    }
  }
}
</script>
