<template>
  <div style="width: 100%; position: relative">
    <v-text-field
      ref="numberHeaderFilter"
      :value="filterItem.fieldValue"
      class="mt-1"
      dense
      hide-details
      multiple
      outlined
      @focus="onFocus"
      @focusout="focusout"
      @input="changeFieldValue"
    >
      <template v-slot:append>
        <v-btn
          v-if="filterItem.fieldValue
              || filterItem.value[0]
              || !filterItem.selectAll
              || !filterItem.includeEmpty
            "
          :loading="numberListLoading"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <v-menu v-else>
          <template v-slot:activator="{on}">
            <v-btn :loading="numberListLoading" icon small style="margin-top: -1px" v-on="on">
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list v-if="header.predicates.length > 0">
            <v-list-item
              v-for="predicate in header.predicates"
              :style="{backgroundColor: filterItem.predicate === predicate ? '#EEEEEE' : 'white'}"
              class="ma-0"
              dense
              @click="filterItem.predicate = predicate"
            >
              {{ $t(predicate) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:prepend-inner>
        <v-chip
          v-if="equalsMode && filterItem.value.length"
          small
          style="margin-top: 1px"
        >
          {{ filterItem.value.length }}
        </v-chip>
        <v-icon v-if="!equalsMode" style="margin-top: 1px">
          {{ filterItem.predicate === 'LESS' ? 'mdi-less-than' : 'mdi-greater-than' }}
        </v-icon>
      </template>
    </v-text-field>
    <div v-if="numbersMenu" class="et-number-filter-menu">
      <v-card class="pa-0 ma-0 number-header-menu-list d-flex flex-column" max-height="400">
        <v-card-text class="overflow-y-auto pa-0 ma-0">
          <v-list v-if="equalsMode">
            <v-list-item class="number-menu-list-item" @click="changeSelectAll()">
              <v-list-item-action class="mr-1">
                <v-checkbox :input-value="selectAllValue"/>
              </v-list-item-action>
              <v-list-item-content>{{ $t('selectAll') }}</v-list-item-content>
            </v-list-item>
            <v-tooltip
              v-for="number in numberList"
              :key="number"
              bottom
              color="black"
              max-width="90vw"
              open-delay="1000"
            >
              <template v-slot:activator="{on}">
                <v-list-item
                  class="number-menu-list-item"
                  @click="changeSelected(number)"
                  v-on="on"
                >
                  <v-list-item-action class="mr-1">
                    <v-checkbox :input-value="!isNumberSelected(number)"/>
                  </v-list-item-action>
                  <v-list-item-content class="pa-0" style="text-align: start; max-height: 40px">
                    {{ number }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              {{ number }}
            </v-tooltip>
          </v-list>
        </v-card-text>
        <v-card-actions class="elevation-1 ma-0 pa-0">
          <v-list-item class="number-menu-list-item" @click="changeIncludeEmpty()">
            <v-list-item-action class="mr-1">
              <v-checkbox :input-value="filterItem.includeEmpty"/>
            </v-list-item-action>
            <v-list-item-content>{{ $t('includeEmpty') }}</v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import messages from '@/componet-locale/et-filter/messages'
import { CONDITIONS } from '@/components/et/view-settings/utils'
import _ from 'lodash'

export default {
  name: 'NumberHeaderFilter',
  i18n: { messages },
  props: {
    tableId: Number,
    header: Object,
    filterItem: Object,
    filter: Array
  },
  data: () => ({
    numberList: [],
    numbersMenu: false,
    autocompleteCancelTokenSource: null,
    numberListLoading: false,
    debounceNumberListUpdate: null
  }),
  mounted () {
    const debounceNumberList = _.debounce(this.updateNumberList, 500)
    this.debounceNumberListUpdate = () => {
      this.numberListLoading = true
      debounceNumberList()
    }
  },
  methods: {
    focusout (e) {
      if (!!e.relatedTarget &&
        (e.relatedTarget.className.includes('number-menu-list-item')
          || e.relatedTarget.className.includes('number-header-menu-list'))
      ) {
        this.$refs.numberHeaderFilter.focus()
      } else {
        this.numbersMenu = false
      }
      if (this.autocompleteCancelTokenSource) {
        this.autocompleteCancelTokenSource.cancel()
      }
    },
    changeFieldValue (v) {
      this.filterItem.fieldValue = v
      if (!this.equalsMode) {
        this.filterItem.value = []
        this.filterItem.selectAll = true
      } else {
        this.debounceNumberListUpdate()
      }
    },
    updateNumberList () {
      if (this.autocompleteCancelTokenSource) this.autocompleteCancelTokenSource.cancel()
      this.autocompleteCancelTokenSource = this.$axios.CancelToken.source()
      this.numberListLoading = true
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.filterItem.fieldValue || '',
          conditions: this.filter
        }, { cancelToken: this.autocompleteCancelTokenSource.token })
        .then(({ data }) => {
          this.numberList = data.map(it => it.value)
        })
        .catch(er => er ? console.error(er.message) : null)
        .finally(() => this.numberListLoading = false)
    },
    changeSelected (number) {
      this.filterItem.value.includes(number)
        ? this.filterItem.value
          .splice(this.filterItem.value.indexOf(number), 1)
        : this.filterItem.value.push(number)

      if (this.filterItem.value.length === this.numberList.length
        && this.filterItem.selectAll
      ) {
        this.filterItem.value = []
        this.filterItem.selectAll = false
      }
    },
    clear () {
      const prevFieldValue = this.filterItem.fieldValue

      this.filterItem.fieldValue = ''
      this.filterItem.value = []
      this.filterItem.selectAll = true
      this.filterItem.includeEmpty = true

      if (prevFieldValue !== '') this.debounceNumberListUpdate()

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    isNumberSelected (number) {
      const isInValue = this.filterItem.value.includes(number)
      return isInValue === this.filterItem.selectAll
    },
    changeIncludeEmpty () {
      this.filterItem.includeEmpty
        = !this.filterItem.includeEmpty
    },
    changeSelectAll () {
      this.filterItem.selectAll = !this.selectAllValue
      this.filterItem.includeEmpty =
        this.filterItem.selectAll
      this.filterItem.value = []
    },
    onFocus (e) {
      this.numbersMenu = true
      if (this.equalsMode && e.sourceCapabilities) {
        this.debounceNumberListUpdate()
      }
    }
  },
  computed: {
    equalsMode () {
      return this.filterItem.predicate === CONDITIONS.EQUAL
    },
    selectAllValue () {
      if (this.filterItem.value.length === 0) {
        return this.filterItem.selectAll
      } else return this.filterItem.value.length === this.numberList.length
    }
  }
}
</script>

<style>
.et-number-filter-menu {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  min-width: 120px
}
</style>
