<template>
  <div>
    <div class="view-data-page">
      <div class="navigation-panel" style="height: 100vh">
        <v-toolbar flat height="56">
          <div>{{ $t('integrations') }}</div>
          <v-spacer/>
          <v-btn icon @click="createIntegration">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <v-list dense>
          <v-list-item
            v-for="integration in integrations"
            :key="integration.id"
            @click="selectIntegration(integration.id)"
          >
            {{ integration.name || $t('noName') }}
          </v-list-item>
        </v-list>
      </div>
      <div class="router-view" style="margin-left: 280px; overflow-y: auto">
        <div v-if="selectedIntegration">
          <vue-file-toolbar-menu
            :content="toolbar"
            class="full-width ma-3"
          />
          <integration-view
            v-if="!editMode"
            :integration="selectedIntegration"
          />
          <integration-edit
            v-else
            ref="integrationEdit"
            :integration="selectedIntegration"
          />
          <div v-else>{{ $t('selectIntegration') }}</div>
        </div>
      </div>
    </div>
    <create-integration-dialog
      ref="createIntegrationDialog"
      @integrationCreated="init"
    />
    <confirmation-dialog
      ref="removeConfirmationDialog"
      :title="$t('removeConfirmation')"
      @confirm="removeIntegration"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import CreateIntegrationDialog from '@/components/admin/integrations/CreateIntegrationDialog.vue'
import IntegrationView from '@/components/admin/integrations/IntegrationView.vue'
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import IntegrationEdit from '@/components/admin/integrations/IntegrationEdit.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import messages from '@/componet-locale/integrations/messages'

export default {
  name: "IntegrationsPage",
  components: { ConfirmationDialog, IntegrationEdit, IntegrationView, CreateIntegrationDialog, VueFileToolbarMenu },
  i18n: { messages },
  data: () => ({
    integrations: [],
    selectedIntegration: null,
    editMode: false
  }),
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      return this.$axios.get('integration/get-all')
        .then(res => this.integrations = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    createIntegration () {
      this.$refs.createIntegrationDialog.open()
    },
    selectIntegration (id) {
      this.$axios.get(`integration/get-by-id/${id}`)
        .then(res => this.selectedIntegration = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    openRemoveConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open(this.selectedIntegration)
    },
    removeIntegration (integration) {
      this.$axios.delete(`integration/remove/${integration.id}`)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataRemoved'))
          this.selectedIntegration = null
        })
    },
    async save () {
      await this.$refs.integrationEdit.save()
      await this.init()
      this.selectIntegration(this.selectedIntegration.id)
    }
  },
  computed: {
    toolbar () {
      return !this.editMode
        ? [
          {
            text: this.$t('button.edit'),
            click:
              () => {
                this.editMode = true
              }
          }
        ]
        : [
          {
            text: this.$t('button.back'),
            click: () => {
              this.editMode = false
            }
          },
          {
            text: this.$t('button.save'),
            click: this.save
          },
          {
            text: this.$t('button.remove'),
            icon: 'delete',
            click: this.openRemoveConfirmationDialog
          }
        ]
    }
  }
}
</script>
