<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="700">
    <v-card class="d-flex flex-column" max-height="90vh">
      <v-card-title class="px-2 pb-0 pt-3">
        {{ et.name }}
      </v-card-title>
      <v-card-text class="px-2 py-4 flex-grow-1 overflow-y-auto full-width">
        <et-item-card
          :et="et"
          :initial-item="{}"
          :item="item"
          :short-mode="true"
          hideReadOnly
          setNullMode
        />
      </v-card-text>
      <div class="px-3">
        <v-expand-transition>
          <div v-if="showQuery && queryHtml && setAbility('ADMIN')">
            <span v-html="queryHtml"/>
          </div>
        </v-expand-transition>
      </div>
      <v-card-actions>
        <v-btn outlined @click="close">{{ $t('button.close') }}</v-btn>
        <v-btn outlined @click="item={}">
          <v-icon class="mr-1" small>mdi-restore</v-icon>
          {{ $t('reset') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          v-if="showQuery && setAbility('ADMIN')"
          :disabled="queryLoading"
          color="primary"
          icon
          small
          @click="loadQueryHtml"
        >
          <v-icon small>mdi-cached</v-icon>
        </v-btn>
        <v-btn v-if="setAbility('ADMIN')" :loading="queryLoading" outlined @click="changeShowQueryMode">
          {{ showQuery ? $t('hideQuery') : $t('showQuery') }}
        </v-btn>
        <v-btn color="primary" outlined @click="openConfirmDialog">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
    <et-confirm-edit-dialog
      ref="etConfirmEditDialog"
      @confirm="save"
    />
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/et-item/messages'
import sharedMessages from '@/componet-locale/et-insert/messages'
import { etServiceMixin } from '@/mixins/et-service-mixin'
import EtItemCard from '@/components/et/EtItemCard.vue'
import EtConfirmEditDialog from '@/components/et/utils/EtConfirmEditDialog.vue'


export default {
  name: "EtEditItemDialog",
  components: { EtConfirmEditDialog, EtItemCard, EtItem: () => import('@/components/et/utils/EtItem.vue') },
  i18n: { messages, sharedMessages },
  mixins: [etServiceMixin],
  data: () => ({
    dialog: false,
    selectedIds: [],
    queryHtml: '',
    showQuery: false,
    queryLoading: false,
    et: null,
    item: {}
  }),
  methods: {
    open (et, selectedIds) {
      this.dialog = true
      this.et = et
      this.selectedIds = selectedIds
    },
    close () {
      this.dialog = false
      this.queryHtml = ''
      this.showQuery = false
      this.item = {}
      this.selectedIds = []
    },
    changeShowQueryMode () {
      if (this.showQuery) {
        this.showQuery = false
        this.queryHtml = ''
      } else {
        this.loadQueryHtml()
      }
    },
    loadQueryHtml () {
      const updateQueryDto = this.getUpdateQueryDto(this.et, this.item, this.selectedIds)
      if (!updateQueryDto) return
      this.queryLoading = true
      this.$axios.post('/et/get-update-query', updateQueryDto)
        .then(res => {
          this.showQuery = true
          this.queryHtml = `<span>${res.data}</span>`
        })
        .catch(e => EventBus.$emit('showErrorMessage', this.$t(e.description || e.message || 'error')))
        .finally(() => this.queryLoading = false)
    },
    openConfirmDialog () {
      const updateQueryDto = this.getUpdateQueryDto(this.et, this.item, this.selectedIDs)
      const fields = updateQueryDto.fieldsValue
      this.$refs.etConfirmEditDialog.open(fields, this.selectedIds.length)
    },
    save () {
      this.updateRow(this.et, this.item, this.selectedIds)
        .then(() => this.$emit('updated'))
    }
  }
}
</script>
