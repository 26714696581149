<template>
  <v-container>

    <v-card-actions>
      <span class="title">{{ $t('settings') }}</span>
      <v-spacer/>
      <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
    </v-card-actions>

    <v-layout row wrap>
      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">SMTP</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in mailSettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" outlined @click="openTestEmail">
            {{ $t('test') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">
          {{ $t('app') }}
          <v-spacer/>
          <v-dialog>
            <template v-slot:activator="{on}">
              <v-btn icon v-on="on">
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <b>Настройки меню:</b> doc, table, et, map, layer, chart, modbus, calc
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in generalSettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">{{ $t('notifications') }}</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in notificationSettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">GIS</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in gisSettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">Active Directory</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in activeDirectorySettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">FTP</v-card-title>
        <v-card-text class="py-0">
          <hidden-text-field v-for="item in ftpSettings" :key="item.id"
                             :hidden="item.isSecured"
                             :label="item.label"
                             :model.sync="item.body"/>
        </v-card-text>
      </v-card>

      <v-card class="ma-2" outlined width="280">
        <v-card-title class="subtitle-1">UI config</v-card-title>
        <v-card-text class="py-0">
          <v-file-input
            v-model="themeConfigFile"
            clearable
            label="Theme (json)"
            @change="saveThemeConfigFile"
            @click:clear="removeThemeConfig"
          >
            <template v-if="themeConfigFile" v-slot:append-outer>
              <v-btn icon @click="downloadThemeConfig">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-file-input>
          <v-file-input
            v-model="fontConfigFile"
            clearable
            label="Font (ttf)"
            @change="saveFontConfigFile"
            @click:clear="removeFontConfig"
          >
            <template v-if="fontConfigFile" v-slot:append-outer>
              <v-btn icon @click="downloadFontConfig">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-file-input>
        </v-card-text>
      </v-card>

    </v-layout>

    <test-email-dialog ref="testEmail"/>

  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/application-settings/messages'
import HiddenTextField from '@/components/utils/HiddenTextField'
import TestEmailDialog from '@/components/admin/TestEmailDialog'

export default {
  name: 'ApplicationSettings',
  components: { HiddenTextField, TestEmailDialog },
  data: () => ({
    list: [],
    themeConfigFile: null,
    fontConfigFile: null
  }),
  i18n: { messages: messages },
  mounted () {
    this.$axios
      .get('settings/get-all')
      .then(response => this.list = response.data)
      .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    this.$axios.get('settings/get-theme-config', { responseType: 'arraybuffer' })
      .then(res => {
        if (!res.data.maxByteLength) return
        const blob = new Blob([res.data], { type: 'text/json' })
        this.themeConfigFile = new File([blob], "theme.json", { type: 'text/json' })
      })
    this.$axios.get('settings/get-font-config', { responseType: 'arraybuffer' })
      .then(res => {
        if (!res.data.maxByteLength) return
        const blob = new Blob([res.data], { type: 'font/ttf' })
        this.fontConfigFile = new File([blob], "font.ttf", { type: 'font/ttf' })
      })
  },
  methods: {
    async save () {
      await this.$axios
        .post('settings/save-all', this.list)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
      await this.$store.dispatch('setGisCredentials')
    },
    openTestEmail () {
      this.$refs.testEmail.open()
    },
    async saveThemeConfigFile () {
      if (!this.themeConfigFile) return
      const formData = new FormData()
      formData.append("file", this.themeConfigFile)
      this.$axios.post('/settings/save-theme-config', formData)
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataSaved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    async saveFontConfigFile () {
      if (!this.fontConfigFile) return
      const formData = new FormData()
      formData.append("file", this.fontConfigFile)
      this.$axios.post('/settings/save-font-config', formData)
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataSaved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    removeThemeConfig () {
      this.$axios.post('/settings/remove-theme-config')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataRemoved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    removeFontConfig () {
      this.$axios.post('/settings/remove-font-config')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('dataRemoved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    downloadThemeConfig () {
      const url = URL.createObjectURL(this.themeConfigFile)
      this.downloadConfigFile(url, 'theme.json')
    },
    downloadFontConfig () {
      const url = URL.createObjectURL(this.fontConfigFile)
      this.downloadConfigFile(url, 'font.ttf')
    },
    downloadConfigFile (url, name) {
      const link = document.createElement('a')
      link.href = url
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  computed: {
    generalSettings () {
      return this.list.filter(i => i.type === 'GENERAL')
    },
    mailSettings () {
      return this.list.filter(i => i.type === 'MAIL')
    },
    gisSettings () {
      return this.list.filter(i => i.type === 'GIS')
    },
    activeDirectorySettings () {
      return this.list.filter(i => i.type === 'ACTIVE_DIRECTORY')
    },
    ftpSettings () {
      return this.list.filter(i => i.type === 'FTP')
    },
    notificationSettings () {
      return this.list.filter(i => i.type === 'NOTIFICATION')
    }
  }
}
</script>
