var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center py-2 px-1"},[(_vm.historyMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('changeHistoryMode')}}},on),[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t('button.back'))+" ")],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.back')))])]},proxy:true}],null,false,523602831)})],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('edit')}}},on),[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t('button.edit'))+" ")],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.edit')))])]},proxy:true}],null,false,3722861313)})],1):_vm._e(),(_vm.editMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('save')}}},on),[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.save')))])]},proxy:true}],null,false,4265262788)})],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1 et-toolbar-btn",attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.$emit('setParent')}}},on),[_c('v-icon',{attrs:{"size":28}},[_vm._v(" mdi-file-tree ")])],1)]}}],null,false,1742405881)},[_c('span',[_vm._v(_vm._s(_vm.$t('setParent')))])])],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1 et-toolbar-btn",attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.$emit('changeHistoryMode')}}},on),[_c('v-icon',{attrs:{"size":28}},[_vm._v(" mdi-history ")])],1)]}}],null,false,3957937871)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.history')))])])],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1 et-toolbar-btn",attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.$emit('share')}}},on),[_c('v-icon',{attrs:{"size":28}},[_vm._v(" mdi-share-variant-outline ")])],1)]}}],null,false,1461550909)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.share')))])])],1):_vm._e(),_c('v-spacer'),(_vm.editMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"color":"black","outlined":""},on:{"click":function($event){return _vm.$emit('edit')}}},on),[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.cancel')))])]},proxy:true}],null,false,956039665)})],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('createNewArticle')}}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.create')))])]},proxy:true}],null,false,2638024582)})],1):_vm._e(),(_vm.viewMode)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 px-1",attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.$emit('remove')}}},on),[_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" "),_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('button.remove')))])]},proxy:true}],null,false,622389632)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }