<template>
  <div class="d-flex full-width" style="height: 100%">
    <portal to="zws-photo-download-btn">
      <v-btn
        :disabled="!blobField.image"
        class="mr-2"
        icon
        @click="download"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </portal>
    <v-img
      v-if="blobField.image && !loading"
      :max-height="maxHeight"
      :max-width="maxWidth"
      :src="blobField.image"
      class="mx-auto"
      contain
      min-height="100px"
      min-width="100px"
    />
    <div v-else class="flex-grow-1 d-flex align-center justify-center" style="margin-top: -100px">
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      />
    </div>
  </div>
</template>

<script>

import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'

export default {
  name: 'ImgFromZulu',
  props: {
    blobField: Object
  },
  data: () => ({
    loading: false
  }),
  mounted () {
    this.getBlob()
  },
  methods: {
    async getBlob () {
      if (this.blobField.image || this.loading) return
      this.loading = true
      await ZwsCommandBuilder.getBlob(this.blobField.url)
        .then(res => this.blobField.image = res)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => {
          this.loading = false
          this.$forceUpdate()
        })
    },
    download () {
      const a = document.createElement('a')
      a.href = this.blobField.image
      const date = this.formatDateDIN(new Date(Date.now()))
      a.download = `${this.blobField.username} [${date}].png`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  computed: {
    maxWidth () {
      return screen.width * 0.7
    },
    maxHeight () {
      return screen.height * 0.8
    }
  }
}
</script>
