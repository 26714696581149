<template>
  <v-dialog v-model="dialog" width="900px">
    <v-card class="d-flex flex-column" height="90vh">
      <v-card-text class="flex-grow-1 overflow-y-hidden pa-0">
        <wiki-article-editor
          v-if="newArticleVersion"
          ref="editor"
          :selected-version="newArticleVersion"
        />
      </v-card-text>
      <v-card-actions class="pa-2">
        <v-btn color="black" outlined @click="close">{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import WikiArticleEditor from '@/components/wiki/WikiArticleEditor.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'NewArticleDialog',
  components: { WikiArticleEditor },
  data: () => ({
    dialog: false,
    newArticleVersion: null
  }),
  methods: {
    open () {
      this.newArticleVersion = {
        title: '',
        indexNumber: '',
        content: ''
      }
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.newArticleVersion = null
    },
    save () {
      const newVersionProps = this.$refs.editor.getNewVersionProps()
      this.newArticleVersion.title = newVersionProps.title
      this.newArticleVersion.indexNumber = newVersionProps.indexNumber
      this.newArticleVersion.content = newVersionProps.content
      this.$axios.post('article/save', this.newArticleVersion)
        .then(res => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('save', res.data.article.id)
          this.close()
        })
        .catch(() => EventBus.$emit('showErrorMessage'))
    }
  }
}
</script>
