<template>
  <div>
    <vuedraggable
      v-model="item.headers"
      class="list-group"
      handle=".handle"
      v-bind="dragOptions"
      @end="drag = false"
      @start="drag = true"
    >
      <transition-group :name="!drag ? 'flip-list' : null" type="transition">
        <v-card
          v-for="(header, i) in item.headers"
          :key="i"
          class="et-header-card my-4 pa-3 list-group-item"
        >
          <v-card-actions class="pa-0 mb-2">
            <v-icon class="handle pointer">mdi-drag-horizontal</v-icon>
            <v-spacer/>
            <v-icon class="pointer" color="red" @click="remove(i)">close</v-icon>
          </v-card-actions>
          <v-text-field
            v-model="header.key"
            :label="$t('key')"
            class="mb-2"
            dense
            hide-details
            outlined
          />
          <v-text-field
            v-model="header.alias"
            :label="$t('alias')"
            class="mb-2"
            dense
            hide-details
            outlined
          />
          <v-text-field
            v-model="header.fieldName"
            :label="$t('title')"
            class="mb-2"
            dense
            hide-details
            outlined
          />
          <v-select
            v-model="header.fieldType"
            :items="fieldTypes"
            :label="$t('fieldType')"
            class="mb-2"
            dense
            hide-details
            outlined
          >
            <template v-slot:selection="{item}">{{ $t(item) }}</template>
            <template v-slot:item="{item}">{{ $t(item) }}</template>
          </v-select>

          <v-textarea
            v-if="header.fieldType === 'LIST'"
            v-model="header.searchOptions"
            :label="$t('searchOptions')"
            :placeholder="$t('useCommaAsDivider')"
            auto-grow
            class="mb-2"
            dense
            hide-details
            outlined
            row-height="12"
          />

          <v-text-field
            v-if="header.updateFromParentTable"
            v-model="header.helperTableName"
            :label="$t('childTableName')"
            class="mb-2"
            dense
            hide-details
            outlined
          />

          <v-text-field
            v-if="header.updateFromParentTable"
            v-model="header.helperPrimaryKey"
            :label="$t('childTablePrimaryKey')"
            class="mb-2"
            dense
            hide-details
            outlined
          />

          <v-text-field
            v-if="header.updateFromParentTable"
            v-model="header.helperUpdateFieldKey"
            :label="$t('updatedFieldKey')"
            class="mb-2"
            dense
            hide-details
            outlined
          />

          <v-text-field
            v-if="header.hasAdditionalString"
            v-model="header.additionalString"
            :label="$t('additionalString')"
            class="mb-2"
            dense
            hide-details
            outlined
          />

          <v-select
            v-if="header.isComment"
            v-model="header.commentField"
            :items="getAvailableHeadersForComment(header)"
            :label="$t('commentField')"
            class="mb-2"
            clearable
            dense
            hide-details
            item-text="fieldName"
            outlined
            return-object
          />

          <div class="d-flex flex-wrap" style="column-gap:20px">
            <v-checkbox
              v-model="header.isComputedField"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('computedField') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.excludeFromTable"
              :disabled="header.isComment"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('excludeFromTable') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.excludeFromForm"
              :disabled="header.isComment"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('excludeFromForm') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.isPrimaryTableKey"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('primaryKey') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="header.isPrimaryTableKey"
              v-model="header.useInInsert"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('useInImport') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.updateFromParentTable"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('updateThroughParentTable') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.hasAdditionalString"
              class="mt-1"
              dense
              hide-details
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('hasAdditionalString') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="header.isComment"
              class="mt-1"
              dense
              hide-details
              @change="v => updateIsComment(header, v)"
            >
              <template v-slot:label>
                <div class="color-black">
                  {{ $t('isComment') }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card>
      </transition-group>
    </vuedraggable>
    <v-btn
      class="full-width"
      style="border: 1px dashed lightgray"
      text
      @click="add"
    >
      <v-icon>mdi-plus</v-icon>
      {{ $t('button.add') }}
    </v-btn>
  </div>
</template>
<script>
import Vuedraggable from 'vuedraggable'
import messages from '@/componet-locale/et-edit/messages'
import { EventBus } from '@/event-bus'

export default {
  name: 'EtHeaderEditor',
  components: { Vuedraggable },
  i18n: { messages },
  props: {
    item: Object
  },
  data: () => ({
    drag: false
  }),
  methods: {
    add () {
      this.item.headers.push({
        fieldType: 'STRING'
      })
    },
    remove (i) {
      this.item.headers.splice(i, 1)
    },
    checkHeadersValid () {
      if (this.item.headers && this.item.headers.length === 0) {
        EventBus.$emit('showErrorMessage', this.$t('addAnyHeaders'))
        return false
      }
      if (this.item.headers.filter(el => el.isPrimaryTableKey).length > 1) {
        EventBus.$emit('showErrorMessage', this.$t('primaryKeyMayBeOnlyOne'))
        return false
      }
      const aliases = this.item.headers.map(el => el.alias)
      if (aliases.filter((el, i) => aliases.indexOf(el) !== i).length > 0) {
        EventBus.$emit('showErrorMessage', this.$t('aliasesMustBeDifferent'))
        return false
      }
      const keys = this.item.headers.map(el => el.key)
      if (keys.filter((el, i) => keys.indexOf(el) !== i).length > 0) {
        EventBus.$emit('showErrorMessage', this.$t('keysMustBeDifferent'))
        return false
      }
      return true
    },
    getAvailableHeadersForComment (header) {
      return this.item.headers.filter(el => !el.excludeFromTable
        && !el.isComment
        && el.alias !== header.alias
      )
    },
    updateIsComment (header, val) {
      if (val) {
        header.excludeFromTable = false
        header.excludeFromForm = false
      }
    }
  },
  computed: {
    fieldTypes () {
      return ['STRING', 'NUMBER', 'DATE', 'BOOLEAN', 'LIST']
    },
    dragOptions () {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  }
}
</script>

<style scoped>

.color-black {
  color: black;
}

.et-header-card {
  border: 1px solid lightgray;
  box-shadow: none;
  background: #f6f4f2
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

</style>
