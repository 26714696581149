<template>
  <view-data-page
    v-if="data"
    :data="data"
    :dialog-mode="dialogMode"
    :selected-view="selectedView"
    :split-screen-mode="splitScreenMode"
    :tab-list="tabList"
    @selectView="selectView"
  >
    <template v-slot:tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :loading="exporting" icon @click="openPreExportSettingsDialog" v-on="on">
            <v-icon>
              cloud_download
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.export') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:actions>
      <v-list-item :disabled="exporting" dense @click="openPreExportSettingsDialog">
        <v-list-item-avatar>
          <v-icon>cloud_download</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('export') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="setAbility('MODERATOR')" dense @click="openReportPlaceholdersDialog()">
        <v-list-item-avatar>
          <v-icon>mdi-file-replace</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('placeholders') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:doc>
      <report-body-viewer v-if="body" :body="body"/>
      <div v-else class="ml-4">{{ $t('selectVersion') }}</div>
    </template>

    <template v-slot:navigation>
      <v-list v-if="selectedView === 'versions'">
        <v-list-item
          v-for="version in versions"
          :key="version.id"
          :style="version.id === selectedVersion.id ? 'background: #d6d6d6' : ''"
          class="pr-1"
          @click="selectVersion(version.id)"
        >
          <v-list-item-content>
            <v-list-item-title @click="" v-text="version.name"/>
            <v-list-item-subtitle v-if="version.author">
              <b>{{ getUserFullName(version.author) }}</b>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ formatDate(version.creationDate) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="setAbility('MODERATOR')" class="mx-0">
            <v-menu
              :nudge-width="200"
              offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_horiz</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="setAbility('ADMIN')" @click="$refs.removeConfirmationDialog.open(version)">
                  <v-list-item-title>
                    {{ $t('button.remove') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="addVersion(version)">
                  <v-list-item-title>
                    {{ $t('button.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadVersionTemplate(version.id)">
                  <v-list-item-title>
                    {{ $t('downloadTemplate') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-btn
          v-if="setAbility('MODERATOR')"
          color="primary" text
          @click="addVersion"
        >
          {{ $t('button.add') }}
          <v-icon right>add</v-icon>
        </v-btn>
      </v-list>
      <report-version-dialog
        ref="reportVersionDialog"
        :report="data"
        @newVersionSaved="onNewVersionSaved"
        @versionUpdated="init"
      />
      <confirmation-dialog
        ref="removeConfirmationDialog"
        :title="$t('confirmDeletion')"
        @confirm="removeReportVersion"
      />
      <report-placeholders-dialog
        ref="reportPlaceholdersDialog"
        @updated="init"
      />
      <pre-export-settings
        ref="preExportSettingsDialog"
        @export="exportVersion"
      />
    </template>
  </view-data-page>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'
import ViewDataPage from '@/components/utils/ViewDataPage'
import { VueEditor } from 'vue2-editor'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/report-view/messages'
import ReportBodyViewer from '@/components/report/ReportBodyViewer.vue'
import ReportVersionDialog from '@/components/report/ReportVersionDialog.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import ReportPlaceholdersDialog from '@/components/report/ReportPlaceholdersDialog.vue'
import downloadFile from '@/services/file-downloader'
import PreExportSettings from '@/components/report/PreExportSettings.vue'

export default {
  name: 'ReportView',
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode: {
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  components: {
    PreExportSettings,
    ReportPlaceholdersDialog,
    ConfirmationDialog,
    ReportVersionDialog,
    ReportBodyViewer,
    ViewDataPage,
    VRuntimeTemplate,
    VueEditor
  },
  i18n: { messages },
  data: () => ({
    selectedView: 'comments',
    tabList: [
      {
        icon: 'message',
        view: 'comments'
      },
      {
        icon: 'history',
        view: 'versions'
      }
    ],
    data: null,
    versions: null,
    exporting: false,
    body: null,
    selectedVersion: null
  }),
  methods: {
    selectView (view) {
      this.selectedView = view
    },
    async init () {
      let id = this.dataId || this.$route.params.id
      await this.$axios
        .get('data/get', { params: { id: id } })
        .then(response => {
          this.data = response.data
        })
        .catch(e => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(e)
        })
      await this.$axios.get(`report/get-all-versions/${this.data.id}`)
        .then(res => {
          this.versions = res.data
          if (!this.versions.length) {
            return this.selectedVersion = null
          }
          this.selectVersion(this.versions[0].id)
        })
    },
    getVersionBody () {
      if (!this.selectedVersion) return this.body = null
      this.$axios.get(`report/get-version-body/${this.selectedVersion.id}`, {
        responseType: 'arraybuffer',
        timeout: 2400000
      })
        .then(res => this.body = res.data)
    },
    async openReportPlaceholdersDialog (version = null) {
      this.$refs.reportPlaceholdersDialog.open(version || this.selectedVersion)
    },
    async openPreExportSettingsDialog () {
      this.$refs.preExportSettingsDialog.open(this.selectedVersion)
    },
    exportVersion (exportSettings) {
      this.exporting = true
      return this.$axios
        .post(`report/export-version`,
          exportSettings,
          {
            params: {
              versionId: this.selectedVersion.id
            },
            responseType: 'arraybuffer',
            timeout: 2400000
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.download = `${this.data.name} (${this.selectedVersion.name}).docx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
        .finally(() => this.exporting = false)
    },
    addVersion (version = null) {
      this.$refs.reportVersionDialog.open(version)
    },
    removeReportVersion (reportVersion) {
      this.$axios
        .post(`report/remove-report-version/${reportVersion.id}`)
        .then(() => {
          this.init()
          EventBus.$emit('showSuccessMessage', this.$t('dataRemoved'))
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    onNewVersionSaved (version) {
      this.init()
      this.openReportPlaceholdersDialog(version)
    },
    selectVersion (versionId) {
      this.$axios.get('report/get-version', {
        params: {
          id: versionId
        }
      })
        .then(res => this.selectedVersion = res.data)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    downloadVersionTemplate (versionId) {
      downloadFile(`report/get-version-template/${versionId}`, `${this.data.name} (${this.selectedVersion.name}) [${this.$t('template')}].docx`)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route.params.id' () {
      this.init()
    },
    selectedVersion () {
      this.getVersionBody()
    }
  }
}
</script>
