<template>
  <component
    :is="getComponent()"
    class="ma-3"
    @emitEventBus="emitEventBus"
  />
</template>

<script>
import Vue from 'vue'
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VDialog,
  VIcon,
  VSelect,
  VSimpleTable,
  VTextarea,
  VTextField
} from 'vuetify/lib'
import { EventBus } from '@/event-bus'

export default {
  name: "IntegrationView",
  props: {
    integration: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitEventBus (action) {
      EventBus.$emit(action.event, action.data)
    },
    getComponent () {
      let { template, data, methods, computed, mounted } = JSON.parse(this.integration.componentTemplate || '{}')

      for (let i in methods) {
        methods[i] = new Function(methods[i].args, methods[i].body)
      }

      for (let i in computed) {
        computed[i] = new Function(computed[i].args, computed[i].body)
      }

      return Vue.component('IntegrationComponent', {
        name: 'IntegrationComponent',
        components: {
          VBtn,
          VTextfield: VTextField,
          VTextarea,
          VSelect,
          VCard,
          VCardActions,
          VCardText,
          VCardTitle,
          VCheckbox,
          VDialog,
          VIcon,
          VSimpleTable
        },
        template: template,
        data: eval(data),
        methods,
        computed,
        mounted: mounted
          ? new Function(mounted.args, mounted.body)
          : new Function()
      })
    }
  }
}
</script>
