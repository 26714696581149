<template>
  <div>
    <div class="simulation-btn-group mb-4 mt-2">
      <v-tooltip color="black" top>
        Запустить
        <template v-slot:activator="{on}">
          <v-btn
            :disabled="!featuresForChange.length"
            :loading="simulationLoading"
            icon
            outlined
            tile
            @click="simulate"
            v-on="on"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip color="black" top>
        Добавить
        <template v-slot:activator="{on}">
          <v-btn
            :disabled="!selectedFeatures.length"
            icon
            outlined
            tile
            @click="addFeaturesForSimulation"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip color="black" top>
        Очистить
        <template v-slot:activator="{on}">
          <v-btn
            :disabled="!featuresForChange.length"
            icon
            outlined
            tile
            @click="clearAll"
            v-on="on"
          >
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <div v-if="featuresForChange.length">
      <v-simple-table>
        <thead>
        <tr>
          <th class="pr-1">Ключ</th>
          <th class="px-1">Режим</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="feature in featuresForChange" style="cursor: pointer"
            @click="emitShowFeatureBySys(feature.elemId)">
          <td class="pr-1">{{ feature.elemId }}</td>
          <td class="py-2 px-1">
            <v-select
              v-model="feature.mode"
              :items="feature.modeList"
              append-icon=""
              class="small-select"
              dense
              hide-details
              item-text="title"
              item-value="index"
              outlined
              return-object
              @click.stop
            />
          </td>
          <td class="px-1">
            <v-btn icon @click.stop="removeFeatureFromSimulation(feature)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div v-if="simulationResultFeaturesDto.length && activeRelations.length">
      <div class="pl-3 py-3 font-weight-bold">
        Найти связанные объекты
        <v-progress-circular
          v-if="relatedFeaturesLoading"
          :size="15"
          indeterminate
        />
      </div>
      <v-list>
        <v-list-item
          v-for="relation in activeRelations"
          :key="relation.id"
          :disabled="relatedFeaturesLoading"
          class="caption"
          @click="showRelatedFeatures(relation)"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ relation.name }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="simulationResultFeaturesDto.length">
      <div class="pl-3 py-3 font-weight-bold">
        В результате операции затронуто {{ simulationResultFeaturesDto.length }} элементов.
        <v-progress-circular
          v-if="mapFeaturesLoading"
          :size="15"
          indeterminate
        />
      </div>
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Новое состояние</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="object in simulationResultFeaturesDto" style="cursor: pointer"
            @click="emitShowFeatureBySys(object.id)">
          <td>{{ object.id }}</td>
          <td>{{ object.state }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div>
      <portal to="simulation-layer">
        <vl-feature
          v-for="feature in featuresForChange"
          :properties="{obj: feature}"
        >
          <component
            :is="getComponent(feature.geometry.type)"
            v-if="feature.geometry"
            :coordinates="feature.geometry.coordinates"
          />

          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="yellow"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="yellow"
            />
          </vl-style-box>
        </vl-feature>
      </portal>
    </div>
  </div>
</template>

<script>

import zwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'
import { mapSimulationMixin } from '@/components/map/zws/simulations/map-simulation-mixin'

export default {
  name: "NetworkAnalyzeSwitchBar",
  mixins: [mapSimulationMixin],
  props: {
    selectedFeatures: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    featuresForChange: [],
    simulationResultFeaturesDto: [],
    layer: null,
    simulationLoading: false,
    mapFeaturesLoading: false,
  }),
  methods: {
    async simulate () {
      this.simulationLoading = true
      EventBus.$emit('clearSelectedFeatures')
      zwsCommandBuilder.analyzeNetworkSwitch(this.layer.layerId, this.featuresForChange.map(f => ({
        id: f.elemId,
        modeId: f.mode.index
      })))
        .then(features => {
          if (features.length === 0) {
            EventBus.$emit('showInfoMessage', 'Анализ переключений вернул пустой результат')
          }
          this.simulationResultFeaturesDto = features
          this.getChangedObjectsWithParams()
        })
        .catch(err => {
          console.error(err.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.simulationLoading = false)
    },
    addFeaturesForSimulation () {
      if (this.selectedFeatures.some(o => o.layer.type !== 'ZWS')) {
        return EventBus.$emit('showInfoMessage', 'Выбраны объекты неподдерживаемого слоя')
      }
      if (!this.layer) {
        this.layer = this.selectedFeatures[0].layer
      }
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
      const featuresToPush = JSON.parse(JSON.stringify(this.selectedFeatures))
      featuresToPush.forEach(f =>
        this.featuresForChange.some(el => el.elemId === f.elemId || el.layer.id !== this.layer.id)
          ? null
          : this.featuresForChange.push(f)
      )
    },
    clearAll () {
      this.featuresForChange = []
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
      this.layer = null
    },
    getChangedObjectsWithParams () {
      const ids = this.simulationResultFeaturesDto
        .filter(el => !this.featuresForChange.some(f => f.elemId === +el.id))
        .map(it => it.id)

      this.mapFeaturesLoading = true
      zwsCommandBuilder.getElemsByID(
        this.layer,
        ids,
        null,
        {
          excludeProps: true,
          excludeModeList: true,
          excludeQueryList: true
        }
      )
        .then(data => {
          this.changedFeatures = data
          this.addGroup(data)
        })
        .finally(() => this.mapFeaturesLoading = false)
    },
    emitShowFeatureBySys (sys) {
      EventBus.$emit('showFeature', this.layer, sys)
    },
    getComponent (geometryType) {
      switch (geometryType) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + geometryType)
      }
    },
    removeFeatureFromSimulation (object) {
      this.featuresForChange = this.featuresForChange.filter(el => object.elemId !== el.elemId)
      this.simulationResultFeaturesDto = []
      this.changedFeatures = []
    }
  },
  computed: {
    activeRelations () {
      return this.layer && this.simulationResultFeaturesDto.length
        ? this.map.simulationRules.filter(relation => relation.sourceLayer.id === this.layer.id)
        : []
    }
  }
}
</script>
