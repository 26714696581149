<template>
  <span>{{ $t('leftChat') }}</span>
</template>

<script>
import messages from '@/componet-locale/system-message-template/messages'

export default {
  name: 'LeavingEventMessage',
  i18n: { messages }
}
</script>
